import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@vaadin/vaadin-icons";
import "@polymer/paper-icon-button";
import "./dialog/logout-dialog-container";

/**
 * `hh-component-logout`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class HHComponentLogout extends PolymerElement {
   static get template() {
      return html`
         <style>
            :host {
               display: flex;
               height: 100%;
            }

            paper-icon-button {
               color: #ccc;
               width: 18px;
               height: 100%;
               padding: 0px;
               margin: 0 10px 0 10px;
            }

            paper-icon-button:hover {
               background-color: #0087cb;
               cursor: default;
            }
         </style>
         <paper-icon-button id="logoutDialog" on-click="popupLogoutDialog" icon="vaadin:power-off"></paper-icon-button>
         <logout-dialog-container id="logoutDialogContainer"></logout-dialog-container>
      </template>
      `;
   }

   static get is() {
      return "hh-component-logout";
   }

   static get properties() {
      return {};
   }

   ready() {
      super.ready();

      this.$.logoutDialogContainer.addEventListener("doLogoutEvent", () => {
         this.dispatchEvent(new CustomEvent("doLogoutEvent"));
      });

      window.addEventListener("message", (e) => {
         switch ((e.data || {}).event) {
         case "POPUP_LOGOUT_DIALOG_OPEN":
            this.$.logoutDialogContainer.callLogoutDialog();
            break;
         default:
            break;
         }
      });

      // 판독문 확인 후 로그아웃 다이얼로그 띄우기
      window.addEventListener("LOGOUT_DIALOG", () => this.$.logoutDialogContainer.callLogoutDialog());
   }

   popupLogoutDialog() {
      // 로그아웃 이벤트 발생
      this.dispatchEvent(new CustomEvent("LOGOUT_EVENT", {bubbles: true, composed: true}));
   }

   setPopup(popup) {
      this.$.logoutDialogContainer.setPopup(popup);
   }

}

window.customElements.define(HHComponentLogout.is, HHComponentLogout);
