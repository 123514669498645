/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
/* eslint-disable no-extend-native */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

// import Prefetch from "hh-prefetch";

import "@hpacs/healthhub-icons/healthhub-icons";
import "@polymer/paper-icon-button/paper-icon-button";
import "@vaadin/vaadin-icons/vaadin-icons";
import "@polymer/paper-tooltip/paper-tooltip";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import { QueryKey } from "../redux/reducers/query.ts";
import { FilmboxActionType } from "../redux/reducers/filmbox";

/**
 * `hh-thumbnail`
 *
 *
 * @customElement
 * @polymer
 */
class HhThumbnail extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-thumbnail";
   }

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               width: 100%;
               height: 100%;
            }

            .container-column {
               display: flex;
               flex-direction: column;
               width: 100%;
               height: 100%;
            }

            .flex-child {
               display: flex;
               flex: 1;
               /*padding: 10px 0;*/
               height: 100%;
            }

            /* prev, next paging */
            .seriesPaging {
               display: flex;
               flex: 1;
               align-items: center;
            }

            .pagingIcon {
               padding: 5px;
               width: 25px;
               height: 25px;
               color: #aaaaaa;
            }

            .pagingIcon:hover {
               color: #0087cb;
            }

            /* next paging + setting button */
            .settingContainer {
               display: flex;
               flex-direction: column;
               align-items: center;
            }

            /* setting button 사이즈 만큼 밑으로 내려가 center로 잡아줌 */
            #nextImages {
               margin-bottom: 70px;
            }

            /* setting button */
            .settingIcon {
               position: relative;
               padding: 0px;
               width: 15px;
               height: 15px;
               color: #aaaaaa;
               top: 18px;
               right: 30px;
            }

            .settingIcon:hover {
               color: #2baab1;
            }

            .container-thumbnail-image {
               width: 100%;
               height: 100%;
               text-align: center;
               background-color: #252934;
            }

            .canvasImage {
               height: 100%;
               width: 100%;
            }

            paper-icon-button, #setSeriesDesc[disabled], #viewType[disabled],
            paper-icon-button, #setSeriesDesc[toggles][active],  #viewType[toggles][active] {
               color: #2baab1;
            }

         </style>

         <div class="container-column">
            <div class="flex-child">
               <div class="seriesPaging">
                  <paper-icon-button id="prevImages" class="pagingIcon" raised icon="vaadin:chevron-left" on-click="prevImages"></paper-icon-button>
                  <paper-tooltip for="prevImages" offset="0" animation-delay="0" animation-entry="scale-up-animation" animation-exit="fade-out-animation">{{t("label.prev")}}</paper-tooltip>
               </div>
               <div id="containerThumbnailImage" class="container-thumbnail-image">
                  <canvas id="canvasImages" class="canvasImage"></canvas>
                  <paper-tooltip id="seriesDescTooltip" for="canvasImages">{{seriesDesc}}</paper-tooltip>
               </div>
               <div class="settingContainer">
                  <div class="wrap-report-setting">
                     <paper-icon-button toggles id="viewType" class="settingIcon" icon="healthhub:stackView" active="{{isStackView}}"></paper-icon-button>
                     <paper-tooltip for="viewType" offset="0" animation-delay="0" animation-entry="scale-up-animation" animation-exit="fade-out-animation">{{t("label.stackTileView")}}</paper-tooltip>
                  </div>
                  <div class="wrap-report-setting">
                     <paper-icon-button toggles id="setSeriesDesc" class="settingIcon" icon="vaadin:font"></paper-icon-button>
                     <paper-tooltip for="setSeriesDesc" offset="0" animation-delay="0" animation-entry="scale-up-animation" animation-exit="fade-out-animation">{{t("label.seriesDescOnOff")}}</paper-tooltip>
                  </div>
                  <div class="wrap-report-setting" on-click="setThumbnailCell">
                     <paper-icon-button id="setThumbnailCell" class="settingIcon" icon="vaadin:grid-small"></paper-icon-button>
                     <paper-tooltip for="setThumbnailCell" offset="0" animation-delay="0" animation-entry="scale-up-animation" animation-exit="fade-out-animation">{{t("label.thumbnailSetting")}}</paper-tooltip>
                  </div>
                  <div class="seriesPaging">
                     <paper-icon-button id="nextImages" class="pagingIcon" raised icon="vaadin:chevron-right" on-click="nextImages"></paper-icon-button>
                     <paper-tooltip for="nextImages" offset="0" animation-delay="0" animation-entry="scale-up-animation" animation-exit="fade-out-animation">{{t("label.next")}}</paper-tooltip>
                  </div>
               </div>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         category: {
            type: Number,
         },
         thumbnailImage: {
            type: Object,
            observer: "onGetThumbnailImageSuccess"
         },
         seriesDesc: {
            type: String,
            value: ""
         },
         // m_prefetch: {
         //    type: Object
         // },
         m_thumbs: {
            type: Map
         },
         maxImageWorker: {
            type: Number,
            value: 10
         },
         type: {
            type: Object,
            TOAST: "toast"
         },
         msg: {
            type: Object,
            NOT_FOUND_ERR: "Downloading HhThumbnail image."
         },
         // 셋팅 버튼 클릭 유무
         _checkThumbnailCell: {
            type: Boolean,
            value: false
         },
         // 셀 정보
         Info: {
            type: Object,
            value: {}
         },
         // 셀 사이즈
         _infoSize: {
            type: Object,
            value: {}
         },
         // 썸네일
         _thumbnail: {
            type: Object,
            value: {}
         },
         _arrImage: {
            type: Array,
            value: []
         },
         _nextImage: {
            type: Boolean,
            value: false
         },
         _prevImage: {
            type: Boolean,
            value: false
         },
         _nextIdx: {
            type: Number,
            value: 0
         },
         // 셀크기 설정
         e_EditMode_SelectCell: {
            type: Number,
            value: 0
         },
         // 셀종류 설정
         e_EditMode_SetPosition: {
            type: Number,
            value: 1
         },
         e_Button_Radio: {
            type: Number,
            value: 1
         },
         // 기본글자
         m_sBaseText: {
            type: String,
            value: "혫"
         },
         // Layout 설정 여부
         _bLayout: {
            type: Boolean,
            value: false
         },
         // Series 갯수
         _seriesLen: {
            type: Number,
            value: 0
         },
         _workListObjId: {
            type: String,
            value: ""
         },
         _thumbnailCell: {
            type: Boolean,
            value: false
         },
         // canvas안의 thumbnail 영역
         g_thumbnailArea: {
            type: Object,
            value: {}
         },
         // tab 이동시 설정되어있는 thumbnail position(seriesIdx, imageIdx)
         g_tabPos: {
            type: Array,
            value: []
         },
         imageIdx: {
            type: Array,
            value: []
         },
         isStackView: {
            type: Boolean,
            value: true,
            observer : "_changedViewType"
         },
         loadingImage: Object,
         notfoundImage: Object,
         log: {
            type: String,
            value: "hh-thumbnail"
         },
         thumbnailRowId: {
            type: String,
            value: ""
         },
         reportRow: {
            type: Object,
            value: {}
         },
      };
   }

   constructor() {
      super();

      this.m_thumbs = new Map();
      // this.loadingImage = new Image();
      // this.notfoundImage = new Image();
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.category = store.getState().common.category;
         this.thumbnailRowId = store.getState().report.thumbnailRow;
         this.reportRow = store.getState().techlistReport.reportRow?.detail;
         if (this.category === 0) { // TODO: Techlist -> fetchThumbnail 호출하여 셋팅하도록 변경. Worklist 셋팅 방법 검토 필요.
            const queryResult = store.getState().query[QueryKey.GET_THUMBNAIL_IMAGE];
            if (queryResult?.image) {
               this.thumbnailImage = store.getState().query[QueryKey.GET_THUMBNAIL_IMAGE];
            }
         }
      });

      this.setLoadingImage();
      this.setNotfoundImage();

      const config = {
         // eslint-disable-next-line no-undef
         url: __CDN_URL__,
         method: "GET",
         headers: { "Authorization": localStorage.getItem("jwt") },
         maxImageWorker: this.maxImageWorker
      };

      // this.m_prefetch = Prefetch.create(config, this.isUseCache());
      //
      // this.m_prefetch.on(Prefetch.EVENTS.IMAGE_DONE, (image, blob) => {
      //    this.setThumbImage(image, (window.URL || window.webkitURL).createObjectURL(blob));
      // });
      //
      // this.m_prefetch.on(Prefetch.EVENTS.IMAGE_NOT_EXISTS, (image) => {
      //    this.setThumbImage(image, this.notfoundImage); // "/resource/images/notfound-image.png");
      // });
      //
      // this.m_prefetch.on(Prefetch.EVENTS.IMAGE_ERROR, (image, message, error) => {
      //    console.error("thumbnail download error", error);
      //
      //    this.setThumbImage(image, this.notfoundImage); // "/resource/images/notfound-image.png");
      // });

      this.startBody();
      this.InitializeEvent();

      // series Description default setting
      this.$.setSeriesDesc.active = true;

      // series Description on/off
      this.$.setSeriesDesc.addEventListener("active-changed", () => { this.drawImages(); });
   }

   onGetThumbnailImageSuccess(thumbnailImage) {
      // console.log(`-> [${this.log}] onGetThumbnailImageSuccess`, this.category, this.id, thumbnailImage);
      const { id, image } = thumbnailImage;
      // Temporary Code
      if (
          (this.category === 0 && this.id === "hhThumbnail") // worklist thumbnail
          || (this.category === 1 && this.id === "techThumbnail") // tech thumbnail
          || (this.category === 0 && this.id === "thumbnailImage") // cd view (link main) thumbnail
      ) {
         this.setExambyId(id, image);
      }
   }

   _changedViewType(isStackView) {
      if(!this.isEmptyObject(this._thumbnail)) {
         if(isStackView) {
            this.seriesPagingClear();
            this.clearCanvas();
            this.setCellThumbnail();
         } else {
            this.seriesPagingClear();
            this.clearCanvas();
            this.setCellThumbnail();
         }
      }
   }

   setCellThumbnail3() {

   }

   isEmptyObject(param) {
      return Object.keys(param).length === 0 && param.constructor === Object;
   }

   setThumbImage(image, src) {
      let series = this.m_thumbs.get(image.series);
      if (!series) {
         series = new Map();
         this.m_thumbs.set(image.series, series);
      }
      series.set(image.id, src);// (window.URL || window.webkitURL).createObjectURL(image.blob));

      if(this.isStackView) {
         const oPos = this._infoSize.Position[image.seriesIdx];
         if (oPos) {
            const { seriesIdx, imageIdx } = (oPos.Cell||{});
            // eslint-disable-next-line
            if(!isNaN(seriesIdx) && !isNaN(imageIdx)) {
               if (this._thumbnail.series[seriesIdx].seriesInstanceUID === image.series
                  && this._thumbnail.series[seriesIdx].image[imageIdx].contentID === image.id) {
                  this.drawImage(oPos);
               }
            }
         }
      } else {
         for (const oPos of this._infoSize.Position) {
            if(oPos.Cell && oPos.Cell.seriesIdx === image.seriesIdx &&  oPos.Cell.imageIdx === image.imageIdx ) {
               this.drawImage(oPos);
            }
         }
      }
   }

   /**
    * function setExamInfo
    * RequestObjectId로 의뢰 이미지 조회 후 출력
    *
    * Create by 김민정 on 2019-01-03 오후 2:46
    * @param {}
    * @return {}
    * */
   setExambyId(id, resultThumbnail) {
      if(this.category === 0){
         if(id !== this.thumbnailRowId) return;
      } else if(this.category === 1) {
         if(id !== this.reportRow.id) return;
      }
      this._workListObjId = id;
      // console.log(id, resultThumbnail);
      this.m_thumbs.clear();
      // this.m_prefetch.reset(this.isUseCache());

      if(this._infoSize.Position && resultThumbnail && resultThumbnail.series){
         this._seriesLen = resultThumbnail.series.length;
         this.seriesPagingClear();
         this.startBody();
         this.setCellThumbnail(resultThumbnail);
         // console.log("draw start");

         (resultThumbnail.series||[]).forEach((series, seriesIdx) => {
            const oPos = this._infoSize.Position[seriesIdx];
            (series.image||[]).forEach((m, imageIdx) => {

               let image;
               if(series.fileType === "KeyImage") {
                  image = {id: m.contentID, imageIdx, seriesIdx, series: m.seriesInstanceUID, study: id, path: `/prefetch/download/image/${ m.contentID  }/large`};
               } else {
                  image = {id: m.contentID, imageIdx, seriesIdx, series: series.seriesInstanceUID, study: id, path: `/prefetch/download/image/${ m.contentID  }/large`};
               }

               let seriesMap = this.m_thumbs.get(image.series);
               if (!seriesMap) {
                  seriesMap = new Map();
                  this.m_thumbs.set(image.series, seriesMap);
               }
               seriesMap.set(image.id, image);// (window.URL || window.webkitURL).createObjectURL(image.blob));

               // eslint-disable-next-line no-undef
               this.setThumbImage(image, `${ __API_URL__ }${image.path}`);

               // if(oPos && (oPos.Cell||{}).imageIdx === imageIdx) {
               //    this.m_prefetch.add(image, 1);
               // }
               // else {
               //    this.m_prefetch.add(image, 2);
               // }
               // return image;
            });
         });
      }
   }

   seriesPagingClear() {
      this.g_tabPos = [];
      this._arrImage = [];
      this._nextIdx = 0;
      this._nextImage = false;
      this._prevImage = false;
   }

   toast(type, msg, isErr) {
      const message = {};
      message.type = type;
      message.msg = msg;
      message.isErr = isErr;

      const param = {};
      param.detail = message;
      this.dispatchEvent(new CustomEvent("toastEvent", param));
   }

   /**
    * function clearThumbnail
    * HhThumbnail 초기화
    *
    * Create by 김민정 on 2018-10-25 오후 4:09
    * @param {}
    * @return {}
    * */
   clearThumbnail() {
      this._workListObjId = null;
      this._seriesLen = 0;
      this.startBody();
   }

   startBody() {
      this.InitializeFunction();
      this.InitializeInfo();
      this.resizeCanvas();
   }

   /**
    * function tabStartBody
    * tab이동시 설정되있는 position대로 thumbnail redraw
    *
    * Create by 김민정 on 2019-01-28 오후 5:40
    * @param {}
    * @return {}
    * */
   tabStartBody() {
      if(!this.g_tabPos.length) { // thumbnail tab이 아닐 경우 초기 thumbnail draw
         this.resizeCanvas();
         this.setCellThumbnail();
      }
      else { // thumbnail tab일 경우 설정되어있는 thumbnail position을 가지고 redraw
         this.drawTabImagesRedraw();
      }
   }

   resizeCanvas() {
      const canvas = this.$.canvasImages;
      // const g_userName = JSON.parse(localStorage.user).name;

      if(this.$.containerThumbnailImage.offsetWidth !== 0 || this.$.containerThumbnailImage.offsetHeight !== 0) {
         // canvas size로 설정
         canvas.width = this.$.containerThumbnailImage.offsetWidth;
         canvas.height = this.$.containerThumbnailImage.offsetHeight;
      }

      this.Info.Width = canvas.width;
      this.Info.Height = canvas.height;

      this.setSelectCellBounds();
      this.setSetPositionCellBounds();
      this.drawBoard();
   }

   InitializeFunction() {
      // padLeft
      if (!String.prototype.padLeft) {
         String.prototype.padLeft = function padLeft(nLength, sPadString) {
            nLength >>= 0;

            sPadString = String(sPadString || " ");

            if (this.length > nLength) {
               return String(this);
            }

            nLength -= this.length;

            if (nLength > sPadString.length) {
               sPadString += sPadString.repeat(nLength / sPadString.length);
            }// if

            return sPadString.slice(0, nLength) + String(this);
         };// function
      }// if
   }

   InitializeInfo() {
      const canvas = this.$.canvasImages;

      this.Info.Context = canvas.getContext("2d");

      this.Info.Width = this.$.containerThumbnailImage.offsetWidth;
      this.Info.Height = this.$.containerThumbnailImage.offsetHeight;

      this.Info.EditMode = this.e_EditMode_SetPosition;
      this.InitializeInfoSetPosition();

      if(this._checkThumbnailCell !== undefined && this._checkThumbnailCell) { // 설정버튼 클릭했을시
         this.Info.EditMode = this.e_EditMode_SelectCell;
         this.InitializeInfoSelectCell();
         this.InitializeInfoSetPosition();
      }
   }

   InitializeInfoSelectCell() {
      this.Info.SelectCell = () => { };

      this.Info.SelectCell.Rows = 10;
      this.Info.SelectCell.Columns = 10;
      this.Info.SelectCell.PaddingWidth = -0.5;
      this.Info.SelectCell.SelectedRow = -1;
      this.Info.SelectCell.SelectedColumn = -1;
      this.Info.SelectCell.SelectedIndex = -1;

      this.Info.SelectCell.BackColor = "rgba(0,0,0,0.8)";
      this.Info.SelectCell.BorderColor = "rgba(210, 210, 210, 0.7)";
      this.Info.SelectCell.SelectedBackColor = "rgba(210, 210, 210, 0.2)";
      this.Info.SelectCell.TextFont = "100px Verdana bold";
      this.Info.SelectCell.TextColor = "rgba(210, 210, 210, 0.7)";

      this.Info.SelectCell.Cells = [];
      this.setSelectCellBounds();
   }

   InitializeInfoSetPosition() {
      const obj = () => { };

      obj.PaddingWidth = 3;

      // 시리즈 갯수 별 layout 지정
      if(this._seriesLen === 0){
         obj.Rows = 2;
         obj.Columns = 2;
      }
      else {
         let seriesLayout;

         for(let rowCol = 1; rowCol <= 10; rowCol++) {
            const thumbnailLayout = rowCol*rowCol;
            if(this._seriesLen <= thumbnailLayout) {
               if(rowCol !== 1){
                  seriesLayout = thumbnailLayout / rowCol;
                  break;
               }
               else {
                  seriesLayout = rowCol;
                  break;
               }
            }
         }

         obj.Rows = seriesLayout;
         obj.Columns = seriesLayout;
      }

      obj.GapHeight = 3;
      obj.ButtonGapWidth = 2;

      obj.Buttons = [];

      obj.Command = "";

      obj.ForeColor = "rgba(210, 210, 210, 0.7)";
      obj.BackColor = "rgba(0,0,0,0.8)";
      obj.BorderColor = "rgba(210, 210, 210, 0.7)";
      obj.SelectedBackColor = "rgba(0,0,0,0.7)";

      obj.TextFont = "12px Verdana";
      obj.TextColor = "rgba(210, 210, 210, 0.7)";

      // obj.Drags = [];
      obj.MouseDragCellIndex = -1;

      obj.Cells = [];

      this.Info.SetPosition = obj;

      this.setSetPositionCellBounds();

      for (let i = 0; i < obj.Cells.length; i++) {
         obj.Cells[i].Command = "";
         obj.Cells[i].SortNo = 0;
      }// for i
   }

   setSetPositionClear() {
      for (let i = 0; i < this.Info.SetPosition.Cells.length; i++) {
         const obj = this.Info.SetPosition.Cells[i];
         obj.Command = "";
      }// for i

      this.drawBoard();
   }

   InitializeEvent() {
      this.$.canvasImages.addEventListener("mousemove", (e) => { this.OnMouseMove(e); }, false);
      this.$.canvasImages.addEventListener("mousedown", (e) => { this.OnMouseDown(e); }, false);
      // this.$.canvasImages.addEventListener("mouseup", (e) => { this.OnMouseUp(e); }, false);
      this.$.canvasImages.addEventListener("mousewheel", (e) => { this.OnMouseWheel(e); }, false);
      this.$.canvasImages.addEventListener("dblclick", (e) => { this.OnMouseDoubleClick(e); }, false);
      this.$.canvasImages.addEventListener("mouseover", (e) => { this.OnMouseOver(e); }, false);
   }

   drawBoard() {
      this.Info.Context.clearRect(0, 0, this.Info.Width, this.Info.Height);
      this.Info.Context.fillStyle = "rgba(0,0,0,0.8)";

      this.Info.Context.fillRect(0, 0, this.Info.Width, this.Info.Height);

      if (this.Info.EditMode === this.e_EditMode_SelectCell) {
         this._bLayout = false;
         this.drawSelectCell();

      }// if
      else if (this.Info.EditMode === this.e_EditMode_SetPosition) {
         this._bLayout = true;
         this.drawSetPosition();
      }// else if

   }

   drawSelectCell() {

      for (let i = 0; i < this.Info.SelectCell.Cells.length; i++) {

         const nX = this.Info.SelectCell.Cells[i].Position.X;
         const nY = this.Info.SelectCell.Cells[i].Position.Y;
         const nW = this.Info.SelectCell.Cells[i].Size.Width;
         const nH = this.Info.SelectCell.Cells[i].Size.Height;

         // 배경
         if (this.Info.SelectCell.Cells[i].Include) {
            this.Info.Context.fillStyle = this.Info.SelectCell.SelectedBackColor;
         }
         else {
            this.Info.Context.fillStyle = this.Info.SelectCell.BackColor;
         }
         this.Info.Context.fillRect(nX, nY, nW, nH);
      }// for i

      // 글
      const sText = "".concat(this.Info.SelectCell.SelectedRow + 1, "X", this.Info.SelectCell.SelectedColumn + 1);
      this.Info.Context.beginPath();
      this.Info.Context.font = this.Info.SelectCell.TextFont;
      this.Info.Context.fillStyle = this.Info.SelectCell.TextColor;
      this.Info.Context.textAlign = "left";
      this.Info.Context.textBaseline = "top";

      const nTextW = this.Info.Context.measureText(sText).width;
      const nTextH = this.Info.Context.measureText(this.m_sBaseText).width + this.Info.Context.measureText(this.m_sBaseText).width / 2;

      const nTextX = (this.Info.Width - nTextW) / 2;
      const nTextY = (this.Info.Height - nTextH) / 2;

      this.Info.Context.fillText(sText, nTextX, nTextY);

      for (let i = 0; i < this.Info.SelectCell.Cells.length; i++) {

         const nX = this.Info.SelectCell.Cells[i].Position.X;
         const nY = this.Info.SelectCell.Cells[i].Position.Y;
         const nW = this.Info.SelectCell.Cells[i].Size.Width;
         const nH = this.Info.SelectCell.Cells[i].Size.Height;

         // 줄
         this.Info.Context.beginPath();
         this.Info.Context.strokeStyle = this.Info.SelectCell.BorderColor;
         this.Info.Context.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
      }// for i

   }

   drawSetPosition() {
      this._infoSize.Position = [];

      // Draw Cells
      for (let i = 0; i < this.Info.SetPosition.Cells.length; i++) {

         const oCell = this.Info.SetPosition.Cells[i];

         const nX = oCell.Position.X;
         const nY = oCell.Position.Y;
         const nW = oCell.Size.Width;
         const nH = oCell.Size.Height;

         // Cell Position, Size 정보
         this._infoSize.Position.push(oCell);

         // Border
         this.Info.Context.beginPath();
         this.Info.Context.strokeStyle = this.Info.SetPosition.BorderColor;
         this.Info.Context.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
      }// for i
   }

   setCellThumbnail(resultThumbnail = this._thumbnail) {
      let nPos = 0;

      if(this.isStackView) {
         for (const series in resultThumbnail.series) {
            if ({}.hasOwnProperty.call(resultThumbnail.series, series)) {
               if (series) {
                  const oCell = {};

                  oCell.seriesIdx = parseInt(series, 10);
                  oCell.lastimageIdx = resultThumbnail.series[series].image.length;

                  if (resultThumbnail.series[series].fileType === "KeyImage") {
                     oCell.imageIdx = 0;
                     this.imageIdx[nPos] = 0;
                  } else {
                     let imageIdx = parseInt((oCell.lastimageIdx / 2) - 1, 10);

                     // technician 이라면 첫번째 이미지부터 보이도록 or radiology 가 아니라면 첫번째 이미지부터 보이도록 표시
                     // TODO: 현재 role 정의가 명확하지 않아 임시 role(technicianThumbnail)을 만들어서 처리함(#17003, #17063), 추후 role 재정의시 변경 필요
                     const {roles} = JSON.parse(localStorage.getItem("user"));
                     if(roles.includes("technicianThumbnail")) imageIdx = 0;

                     oCell.imageIdx = imageIdx;
                     this.imageIdx[nPos] = imageIdx;
                  }

                  let objImg = {};

                  const arrSize = [];

                  for (const img of resultThumbnail.series[series].image) {

                     const imgSize = {};

                     imgSize.width = img.width;
                     imgSize.height = img.height;
                     imgSize.size = imgSize.width * imgSize.height;

                     arrSize.push(imgSize);
                  }

                  objImg = arrSize;

                  oCell.imgSize = arrSize;

                  if (this._infoSize.Position.length > nPos && series) {
                     this._infoSize.Position[nPos].Cell = oCell;
                  }

                  if (this._infoSize.Position.length <= nPos) {
                     this._nextImage = true;
                  }

                  this._arrImage.push(oCell);

                  nPos++;
               }
            }
         }

         this._thumbnail = resultThumbnail;

         this.clearCanvas();
         this.drawImages();

      } else {
         // this.clearCanvas();

         let seriesIdx = 0;
         let nPos = 0;
         for (const {image} of resultThumbnail.series) {
            let imgIdx = 0;
            for (const img of image) {
               const oCell = {};
               oCell.seriesIdx = seriesIdx;
               oCell.imageIdx = imgIdx;
               oCell.lastimageIdx = imgIdx;

               const arrSize = [];
               const imgSize = {};

               imgSize.width = img.width;
               imgSize.height = img.height;
               imgSize.size = imgSize.width*imgSize.height;
               arrSize.push(imgSize);

               oCell.imgSize = arrSize;
               if(this._infoSize.Position.length > nPos){
                  this._infoSize.Position[nPos].Cell = oCell;
                  // this.drawImage(this._infoSize.Position[nPos]);

               } else {
                  this._nextImage = true;
               }

               this._arrImage.push(oCell);

               imgIdx++;
               nPos++;
            }
            seriesIdx++;
         }
         this._thumbnail = resultThumbnail;

         this.clearCanvas();
         this.drawImages();
      }

   }

   redrawCellThumbnail() {
      for (let i = 0; i < this._infoSize.Position.length; i++) {
         if(this._arrImage[this._nextIdx + i]) {
            this._infoSize.Position[i].Cell = this._arrImage[this._nextIdx + i];
         } else {
            this._infoSize.Position[i].Cell = null;
         }
      }
      this.clearCanvas();
      this.drawImages(true);
   }

   // canvas 초기화
   clearCanvas() {
      for (const i in this._infoSize.Position){
         if ({}.hasOwnProperty.call(this._infoSize.Position, i)) {
            const oCell = this._infoSize.Position[i];
            this.Info.Context.clearRect(oCell.Position.X + 2, oCell.Position.Y + 2, oCell.Size.Width - 3, oCell.Size.Height - 3);
            this.setSetPositionClear();
         }
      }
   }

   drawTabImagesRedraw() {
      if(this._bLayout){

         for (const series in this.g_tabPos.length) {
            if ({}.hasOwnProperty.call(this.g_tabPos.length, series)) {
               const oPos = this._infoSize.Position[series];
               if(oPos !== undefined && oPos.Cell !== null) {
                  this.drawImage(oPos);
               }
               else {
                  break;
               }
            }
         }
      }
   }

   drawImages(isRedraw = false) {
      if(!Object.keys(this._thumbnail).length) return;

      if(this._bLayout){
         this.drawBoard();
         let index = 0;
         for (const series of this._thumbnail.series) {
            // if ({}.hasOwnProperty.call(this._thumbnail.series, series)) {
            if(series) {
               if(this.isStackView) {
                  const oPos = this._infoSize.Position[index];

                  if(oPos !== undefined && oPos.Cell !== null) {
                     this.g_tabPos.push(oPos);
                     if(!isRedraw) {
                        this.drawImage(oPos);
                     } else {
                        this.redrawImage(oPos);
                     }
                  }
                  else {
                     break;
                  }
                  index++;
               } else {
                  for (const img of series.image) {
                     const oPos = this._infoSize.Position[index];

                     if(oPos !== undefined && oPos.Cell !== null) {
                        this.g_tabPos.push(oPos);
                        if(!isRedraw) {
                           this.drawImage(oPos);
                        } else {
                           this.redrawImage(oPos);
                        }
                     }
                     else {
                        break;
                     }

                     index++;
                  }
               }
            }
         }
      }
   }

   getFile(seriesId, imageId) {
      return new Promise((resolve) => {
         const series = this.m_thumbs.get(seriesId);
         if(series) {
            const image = series.get(imageId);
            if (image) resolve(image);
            else resolve(this.loadingImage);
         } else {
            resolve(this.loadingImage);
         }
      });
   }

   drawImage(oPos) {
      const resizeImg = this.resizeImg(oPos);

      const img = new Image();

      this.getFile(resizeImg.seriesId, resizeImg.imageId).then((src) => {
         return new Promise((resolve) => {
            const checkID = this._thumbnail.studyInstanceUID;
            img.onload = () => {
               if(checkID === this._thumbnail.studyInstanceUID) {
                  if(oPos.Cell) {
                     const imageIndex = this._thumbnail.series[oPos.Cell.seriesIdx];
                     const imgIndex = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx];
                     if(imageIndex && imgIndex){
                        this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].img = img;
                        resolve(img);
                     }
                  }
               }
            };
            img.src = src;
         }).then((value) => {
            this.Info.Context.drawImage(value, oPos.Position.X+resizeImg.centerWidth, oPos.Position.Y+resizeImg.centerHeight, resizeImg.resizeWidth-2, resizeImg.resizeHeight-2);

            if(oPos.Cell){
               // let seriesDesc = "";
               // if(this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].seriesDescription) seriesDesc = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].seriesDescription;

               const {seriesDescription} = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx];
               let seriesDesc = seriesDescription || (this._thumbnail.series[oPos.Cell.seriesIdx].seriesDescription || "");

               const imgTotalCnt = this._thumbnail.series[oPos.Cell.seriesIdx].image.length;
               const imgCnt = oPos.Cell.imageIdx + 1;
               const cntInfo = `${imgCnt  }/${  imgTotalCnt}`;
               const {fileType} = this._thumbnail.series[oPos.Cell.seriesIdx];


               if (seriesDesc === "hie_unknown" || !seriesDesc) {
                  if (fileType === "KeyImage") {
                     seriesDesc = "Key";
                  } else {
                     seriesDesc = "N/A";
                  }
               }

               seriesDesc = seriesDesc.replace(/^\s*|\s*$/g, "");

               this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].seriesDesc = seriesDesc;
               this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].cntInfo = cntInfo;

               if (this.$.setSeriesDesc.active) {
                  if (fileType === "KeyImage") {
                     this.drawTextDesc(seriesDesc, oPos.Position.X + 5, oPos.Position.Y + 5, resizeImg.maxWidth - 10, 1.0, "red");
                  } else {
                     this.drawTextDesc(seriesDesc, oPos.Position.X + 5, oPos.Position.Y + 5, resizeImg.maxWidth - 10, 1.0, "white");
                  }
                  this.drawTextCnt(cntInfo, oPos.Position.X + 5, oPos.Position.Y + resizeImg.maxHeight - 2);
               }
            }
         });
      });
   }

   resizeImg(oPos) {
      const seriesId = this._thumbnail.series[oPos.Cell.seriesIdx].seriesInstanceUID;
      const imageId = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].contentID;

      // 원본 이미지 크기
      const imgWidth = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].width;
      const imgHeight = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx].height;

      // cell 크기
      const maxWidth = oPos.Size.Width;
      const maxHeight = oPos.Size.Height;
      // 사이즈 조정 이미지 크기
      let resizeWidth;
      let resizeHeight;

      const ratio = Math.min((maxWidth/imgWidth), (maxHeight/imgHeight));
      resizeWidth = imgWidth - 2;
      resizeHeight = imgHeight - 2;
      if(ratio < 1) {
         resizeWidth = Math.round(imgWidth * ratio) - 2;
         resizeHeight = Math.round(imgHeight * ratio) - 2;
      }
      // console.log(`cell: [${maxWidth}, ${maxHeight}]`, `image: [${imgWidth}, ${imgHeight}]`, "ratio: ", ratio, `resize: [${resizeWidth}, ${resizeHeight}]`);

      // 이미지 비율
      // if (imgWidth > imgHeight) {
      //    if (maxHeight < maxWidth) {
      //       resizeHeight = maxHeight;
      //       resizeWidth = (resizeHeight / imgHeight) * imgWidth;
      //
      //       if(maxWidth < resizeWidth) {
      //          resizeWidth = maxWidth;
      //       }
      //    }
      //    else {
      //       resizeWidth = maxWidth;
      //       resizeHeight = (resizeWidth / imgWidth) * imgHeight;
      //
      //       if(maxHeight < resizeHeight) {
      //          resizeHeight = maxHeight;
      //       }
      //    }
      // }
      // else if (imgWidth < imgHeight) {
      //    if (maxHeight < maxWidth) {
      //       resizeHeight = maxHeight;
      //       resizeWidth = (resizeHeight / imgHeight) * imgWidth;
      //
      //       if(maxWidth < resizeWidth) {
      //          resizeWidth = maxWidth;
      //       }
      //    }
      //    else {
      //       resizeWidth = maxWidth;
      //       resizeHeight = (resizeWidth / imgWidth) * imgHeight;
      //
      //       if(maxHeight < resizeHeight) {
      //          resizeHeight = maxHeight;
      //       }
      //    }
      // } else if (maxHeight < maxWidth) {
      //    resizeHeight = maxHeight;
      //    resizeWidth = (resizeHeight / imgHeight) * imgWidth;
      //
      //    if(maxWidth < resizeWidth) {
      //       resizeWidth = maxWidth;
      //    }
      // }
      // else {
      //    resizeWidth = maxWidth;
      //    resizeHeight = (resizeWidth / imgWidth) * imgHeight;
      //
      //    if(maxHeight < resizeHeight) {
      //       resizeHeight = maxHeight;
      //    }
      // }

      // 비율 맞춘 이미지가 클 경우 축소
      const scaleX = resizeWidth / imgWidth;
      const scaleY = resizeHeight / imgHeight;

      let scale = (scaleX < scaleY) ? scaleX : scaleY;

      if (scale < 0.01) scale = 0.01;

      resizeWidth = scale * imgWidth;
      resizeHeight = scale * imgHeight;

      // 이미지 중앙에 위치
      let centerWidth = (maxWidth - resizeWidth) / 2;
      let centerHeight = (maxHeight - resizeHeight) / 2;

      if(centerWidth === 0){
         centerWidth = 2;
      }

      if(centerHeight === 0){
         centerHeight = 2;
      }
      return {
         seriesId,
         imageId,
         maxWidth,
         maxHeight,
         resizeWidth,
         resizeHeight,
         centerWidth,
         centerHeight
      };
   }

   redrawImage(oPos) {
      const resizeImg = this.resizeImg(oPos);
      const {img, seriesDesc, cntInfo} = this._thumbnail.series[oPos.Cell.seriesIdx].image[oPos.Cell.imageIdx];
      const {fileType} = this._thumbnail.series[oPos.Cell.seriesIdx];

      this.Info.Context.drawImage(img, oPos.Position.X+resizeImg.centerWidth, oPos.Position.Y+resizeImg.centerHeight, resizeImg.resizeWidth-2, resizeImg.resizeHeight-2);

      if (this.$.setSeriesDesc.active) {
         if (fileType === "KeyImage") {
            this.drawTextDesc(seriesDesc, oPos.Position.X + 5, oPos.Position.Y + 5, resizeImg.maxWidth - 10, 1.0, "red");
         } else {
            this.drawTextDesc(seriesDesc, oPos.Position.X + 5, oPos.Position.Y + 5, resizeImg.maxWidth - 10, 1.0, "white");
         }
         this.drawTextCnt(cntInfo, oPos.Position.X + 5, oPos.Position.Y + resizeImg.maxHeight - 2);
      }
   }

   /**
    * multi line text(series description)
    * canvas에 text(series description)를 여러줄로 표현
    *
    * Create by joohyukjung on 16/04/2019 4:54 PM
    * @param {}
    * @return {}
    * */
   drawTextDesc(text, x, y, fieldWidth, spacing, color) {
      this.Info.Context.beginPath();
      this.Info.Context.fillStyle = color;
      this.Info.Context.textAlign = "left";
      this.Info.Context.textBaseline = "top";
      this.Info.Context.font = "15px Consolas";

      let line = "";
      const fontSize = parseFloat(this.Info.Context.font);
      let currentY = y;
      // Info.Context.textBaseline = "top";
      for(let i=0; i<text.length; i++) {
         const tempLine = line + text[i];
         const tempWidth = this.Info.Context.measureText(tempLine).width;

         if (tempWidth < fieldWidth && text[i] !== "\n") {
            line = tempLine;
         }
         else {
            this.Info.Context.fillText(line, x, currentY);
            if(text[i] !== "\n") line = text[i];
            else line = "";
            currentY += fontSize*spacing;
         }
      }
      this.Info.Context.fillText(line, x, currentY);
      this.Info.Context.rect(x, y, fieldWidth, currentY-y+fontSize*spacing);
      // Info.Context.stroke();
   }

   /**
    * 이미지 수 / 시리즈 수 표시
    *
    * Create by joohyukjung on 16/04/2019 6:35 PM
    * @param {}
    * @return {}
    * */
   drawTextCnt(text, x, y) {
      this.Info.Context.beginPath();
      this.Info.Context.fillStyle = "white";
      this.Info.Context.textAlign = "left";
      this.Info.Context.textBaseline = "bottom";
      this.Info.Context.font = "13px Consolas";
      this.Info.Context.fillText(text, x, y);
   }


   // Mouse Wheel Event HhThumbnail Image
   drawWheelImages(e, nX, nY){
      if(this.isStackView) {
         for (const i in this._infoSize.Position) {
            if ({}.hasOwnProperty.call(this._infoSize.Position, i)) {
               const nL = this._infoSize.Position[i].Position.X;
               const nT = this._infoSize.Position[i].Position.Y;
               const nW = this._infoSize.Position[i].Size.Width;
               const nH = this._infoSize.Position[i].Size.Height;
               const nR = nL + nW;
               const nB = nT + nH;

               if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
                  if(this._infoSize.Position[i].Cell !== undefined && this._infoSize.Position[i].Cell !== null){ // 이미지가 있을때
                     if (e.wheelDelta > 0) { // 휠을 올렸을때
                        const firstImgSize = this._infoSize.Position[i].Cell.imgSize[0].size;
                        const firstWidth = this._infoSize.Position[i].Cell.imgSize[0].width;
                        const imgIdx = this._infoSize.Position[i].Cell.imageIdx;

                        // 첫번째 이미지 보다 작을 때 캔버스 클리어
                        if(firstImgSize!==this._infoSize.Position[i].Cell.imgSize[imgIdx].size
                           || firstWidth!==this._infoSize.Position[i].Cell.imgSize[imgIdx].width){
                           this.clearCanvas();
                        }

                        this._infoSize.Position[i].Cell.imageIdx -= 1;

                        this.imageIdx[i] -= 1;

                        if(this._infoSize.Position[i].Cell.imageIdx < 0){
                           this._infoSize.Position[i].Cell.imageIdx = 0;
                           this.imageIdx[i] = 0;
                           break;
                        }
                        else {
                           this.drawImages();
                        }
                     }
                     else if (e.wheelDelta < 0) { // 휠을 내렸을때
                        this._infoSize.Position[i].Cell.imageIdx += 1;
                        this.imageIdx[i] += 1;

                        if(this._infoSize.Position[i].Cell.imageIdx >= this._infoSize.Position[i].Cell.lastimageIdx){
                           this._infoSize.Position[i].Cell.imageIdx = (this._infoSize.Position[i].Cell.lastimageIdx-1);
                           this.imageIdx[i] = (this._infoSize.Position[i].Cell.lastimageIdx-1);
                           break;
                        }
                        else {
                           const firstImgSize = this._infoSize.Position[i].Cell.imgSize[0].size;
                           const firstWidth = this._infoSize.Position[i].Cell.imgSize[0].width;
                           const imgIdx = this._infoSize.Position[i].Cell.imageIdx;

                           // 첫번째 이미지 보다 작을 때 캔버스 클리어
                           if(firstImgSize!==this._infoSize.Position[i].Cell.imgSize[imgIdx].size
                              || firstWidth!==this._infoSize.Position[i].Cell.imgSize[imgIdx].width){
                              this.clearCanvas();
                           }
                           this.drawImages();
                        }
                     }

                     const {seriesIdx, imageIdx} = this._infoSize.Position[i].Cell;
                     // series Desc Tooltip
                     this.SetMouseOver_SetTooltip(seriesIdx, imageIdx);
                  } // if
               } // if
            }
         }
      }
      else if(!this.stackView) {
         if (e.wheelDelta > 0) {
            this.prevImages();
         }
         else if (e.wheelDelta < 0) {
            this.nextImages();
         }
      }
   }

   setSelectCellBounds() {
      if (this.Info.Width < 1 || this.Info.Height < 1) {
         return;
      }

      if (this.Info.EditMode !== this.e_EditMode_SelectCell) {
         return;
      }

      const nStartCellX = this.Info.SelectCell.PaddingWidth;
      const nStartCellY = this.Info.SelectCell.PaddingWidth;

      const nRow = this.Info.SelectCell.Rows;
      const nCol = this.Info.SelectCell.Columns;

      const nOneWidth = parseInt(this.Info.Width / nCol, 10)+nStartCellX;
      const nOneHeight = parseInt(this.Info.Height / nRow, 10)+nStartCellY;

      this.Info.SelectCell.Cells = [];
      this.Info.SelectCell.SelectedRow = -1;
      this.Info.SelectCell.SelectedColumn = -1;
      this.Info.SelectCell.SelectedIndex = -1;

      for (let i = 0; i < nRow; i++) {
         for (let j = 0; j < nCol; j++) {

            const cellInfo = {};
            cellInfo.Row = i;
            cellInfo.Col = j;

            const nX = (cellInfo.Col * nOneWidth);
            const nY = (cellInfo.Row * nOneHeight);

            cellInfo.Position = this.newPoint(nX, nY);
            cellInfo.Size = this.newSize(nOneWidth, nOneHeight);

            this.Info.SelectCell.Cells.push(cellInfo);
         }// for j
      }// for i
   }

   setSetPositionCellBounds() {
      if (this.Info.Width < 1 || this.Info.Height < 1) {
         return;
      }

      if (this.Info.EditMode !== this.e_EditMode_SetPosition) {
         return;
      }

      const nStartCellX = this.Info.SetPosition.PaddingWidth;
      const nStartCellY = this.Info.SetPosition.PaddingWidth;

      this.Info.SetPosition.Cells = [];

      const nRow = this.Info.SetPosition.Rows;
      const nCol = this.Info.SetPosition.Columns;

      const nWholeWidth = this.Info.Width - (this.Info.SetPosition.PaddingWidth * 2);
      const nWholeHeight = this.Info.Height - this.Info.SetPosition.PaddingWidth - nStartCellY;

      const nOneWidth = parseInt(nWholeWidth / nCol, 10);
      const nOneHeight = parseInt(nWholeHeight / nRow, 10);

      for (let i = 0; i < nRow; i++) {
         for (let j = 0; j < nCol; j++) {

            const cellInfo = {};
            cellInfo.Row = i;
            cellInfo.Col = j;

            const nX = (cellInfo.Col * nOneWidth) + nStartCellX;
            const nY = (cellInfo.Row * nOneHeight) + nStartCellY;

            cellInfo.Position = this.newPoint(nX, nY);
            cellInfo.Size = this.newSize(nOneWidth, nOneHeight);

            this.Info.SetPosition.Cells.push(cellInfo);
         }// for j
      }// for i
   }

   newPoint(nX, nY) {
      const Point = {};
      Point.X = nX;
      Point.Y = nY;

      return Point;
   }

   newSize(nWidth, nHeight) {
      const Size = {};
      Size.Width = nWidth;
      Size.Height = nHeight;

      return Size;
   }

   OnMouseMove(e) {
      const canvas = this.$.canvasImages;
      const bound = canvas.getBoundingClientRect();
      const bw = 5;

      const nX = (e.x - bound.left - bw) * (canvas.offsetWidth / (bound.width - bw * 2));
      const nY = (e.y - bound.top - bw) * (canvas.offsetHeight / (bound.height - bw * 2));

      const nBtn = e.buttons;

      if (this.Info.EditMode === this.e_EditMode_SelectCell) {

         this.SetMouseMove_SelectCell(nX, nY, nBtn);

      }
      else {
         // drag
         this.SetMouseMove_SetPosition(nX, nY, nBtn);
      }
   }

   OnMouseDown(e) {
      const canvas = this.$.canvasImages;
      const bound = canvas.getBoundingClientRect();
      const bw = 5;

      const nX = (e.x - bound.left - bw) * (canvas.offsetWidth / (bound.width - bw * 2));
      const nY = (e.y - bound.top - bw) * (canvas.offsetHeight / (bound.height - bw * 2));
      const nBtn = e.buttons;

      if (this.Info.EditMode === this.e_EditMode_SelectCell) {
         // layout 설정
         this.SetMouseDown_SelectCell(nX, nY, nBtn);

         if(this._thumbnail.series) {
            // layout 설정 후 썸네일 이미지 Redraw
            this.seriesPagingClear();
            this.setCellThumbnail();
         }
      }
   }

   OnMouseWheel(e) {
      const canvas = this.$.canvasImages;
      const bound = canvas.getBoundingClientRect();
      const bw = 5;

      const nX = (e.x - bound.left - bw) * (canvas.offsetWidth / (bound.width - bw * 2));
      const nY = (e.y - bound.top - bw) * (canvas.offsetHeight / (bound.height - bw * 2));

      this.drawWheelImages(e, nX, nY);
   }

   OnMouseDoubleClick(e) {
      // let nSelected = Info.MouseDragCellIndex;
      // let oPos = _infoSize.Position[nSelected];
      // console.log(oPos.Cell.imageIdx+1);

      const canvas = this.$.canvasImages;
      const bound = canvas.getBoundingClientRect();
      const bw = 5;

      const nX = (e.x - bound.left - bw) * (canvas.offsetWidth / (bound.width - bw * 2));
      const nY = (e.y - bound.top - bw) * (canvas.offsetHeight / (bound.height - bw * 2));
      const nBtn = 1;

      if (this.Info.EditMode === this.e_EditMode_SetPosition) {
         this.SetMouseDown_SetPosition(nX, nY, nBtn);
      }

      // HhThumbnail Canvas에 이미지가 출력되었을때 실행
      if(this._arrImage.length > 0) {
         // HhThumbnail Canvas영역 안에서만 실행
         if (nX >= this.g_thumbnailArea.nL && nX <= this.g_thumbnailArea.nR && nY >= this.g_thumbnailArea.nT && nY <= this.g_thumbnailArea.nB) {
            // HhThumbnail Selected Series Info
            if (this.Info.MouseDownCellIndex !== undefined && this._infoSize.Position[this.Info.MouseDownCellIndex] !== undefined) {
               if (this._infoSize.Position[this.Info.MouseDownCellIndex].Cell !== undefined) {
                  const oViewImage = this._infoSize.Position[this.Info.MouseDownCellIndex];
                  const imageIndex = oViewImage.Cell.imageIdx + 1;
                  const seriesId = this._thumbnail.series[oViewImage.Cell.seriesIdx].seriesInstanceUID;
                  const param = {
                     "seriesId": seriesId,
                     "imageIndex": imageIndex,
                     "caseId": this._workListObjId,
                  };
                  store.dispatch({ type: FilmboxActionType.OPEN_FILMBOX, payload: param });
                  store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
               }
            }
         }
      }
   }

   OnMouseOver(e) {
      const canvas = this.$.canvasImages;
      const bound = canvas.getBoundingClientRect();
      const bw = 5;

      const nX = (e.x - bound.left - bw) * (canvas.offsetWidth / (bound.width - bw * 2));
      const nY = (e.y - bound.top - bw) * (canvas.offsetHeight / (bound.height - bw * 2));

      const nBtn = e.buttons;

      this.SetMouseMove_SetPosition(nX, nY, nBtn);
   }

   SetMouseMove_SelectCell(nX, nY) {
      this.$.seriesDescTooltip.playAnimation("exit");

      let bRedraw = false;
      let bMatch = false;
      const nSelected = this.Info.SelectCell.SelectedIndex;

      if (nSelected > -1 && this.Info.SelectCell.Cells.length > nSelected) {
         const nL = this.Info.SelectCell.Cells[nSelected].Position.X;
         const nT = this.Info.SelectCell.Cells[nSelected].Position.Y;
         const nW = this.Info.SelectCell.Cells[nSelected].Size.Width;
         const nH = this.Info.SelectCell.Cells[nSelected].Size.Height;
         const nR = nL + nW;
         const nB = nT + nH;

         if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
            bMatch = true;
         }// if
      }

      if (!bMatch) {
         this.Info.SelectCell.SelectedIndex = -1;
         this.Info.SelectCell.SelectedRow = -1;
         this.Info.SelectCell.SelectedColumn = -1;

         for (let i = 0; i < this.Info.SelectCell.Cells.length; i++) {

            const nL = this.Info.SelectCell.Cells[i].Position.X;
            const nT = this.Info.SelectCell.Cells[i].Position.Y;
            const nW = this.Info.SelectCell.Cells[i].Size.Width;
            const nH = this.Info.SelectCell.Cells[i].Size.Height;
            const nR = nL + nW;
            const nB = nT + nH;

            if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {

               this.Info.SelectCell.SelectedIndex = i;
               this.Info.SelectCell.SelectedRow = this.Info.SelectCell.Cells[i].Row;
               this.Info.SelectCell.SelectedColumn = this.Info.SelectCell.Cells[i].Col;

               const nSelCol = this.Info.SelectCell.SelectedColumn;
               const nSelRow = this.Info.SelectCell.SelectedRow;

               bMatch = true;
               bRedraw = true;

               for (let j = 0; j < this.Info.SelectCell.Cells.length; j++) {
                  const nCol = this.Info.SelectCell.Cells[j].Col;
                  const nRow = this.Info.SelectCell.Cells[j].Row;

                  if (nCol <= nSelCol && nRow <= nSelRow) {
                     this.Info.SelectCell.Cells[j].Include = true;
                  }
                  else {
                     this.Info.SelectCell.Cells[j].Include = false;
                  }
               }// for j

               break;
            }// if
         }// for i

      }// if

      if (bRedraw) {
         this.drawBoard();
      }
   }

   SetMouseMove_SetPosition(nX, nY) {
      const bRedraw = false;
      let bMatch = false;
      const nSelected = this.Info.MouseDragCellIndex;

      if (nSelected > -1 && this.Info.SetPosition.Cells.length > nSelected) {
         const nL = this.Info.SetPosition.Cells[nSelected].Position.X;
         const nT = this.Info.SetPosition.Cells[nSelected].Position.Y;
         const nW = this.Info.SetPosition.Cells[nSelected].Size.Width;
         const nH = this.Info.SetPosition.Cells[nSelected].Size.Height;
         const nR = nL + nW;
         const nB = nT + nH;

         if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {

            if(this.Info.SetPosition.Cells[nSelected] && this.Info.SetPosition.Cells[nSelected].Cell) {
               const {seriesIdx, imageIdx} = this.Info.SetPosition.Cells[nSelected].Cell;
               // series Desc Tooltip
               this.SetMouseOver_SetTooltip(seriesIdx, imageIdx);
            }

            bMatch = true;
         }// if
         else {
            this.$.seriesDescTooltip.playAnimation("exit");
         }
      }

      if (!bMatch) {
         for (let i = 0; i < this.Info.SetPosition.Cells.length; i++) {
            const nL = this.Info.SetPosition.Cells[i].Position.X;
            const nT = this.Info.SetPosition.Cells[i].Position.Y;
            const nW = this.Info.SetPosition.Cells[i].Size.Width;
            const nH = this.Info.SetPosition.Cells[i].Size.Height;
            const nR = nL + nW;
            const nB = nT + nH;

            if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
               this.Info.MouseDragCellIndex = i;

               if(this.Info.SetPosition.Cells[i] && this.Info.SetPosition.Cells[i].Cell) {
                  const {seriesIdx, imageIdx} = this.Info.SetPosition.Cells[i].Cell;
                  // series Desc Tooltip
                  this.SetMouseOver_SetTooltip(seriesIdx, imageIdx);
               }

               bMatch = true;
               // break;
            }// if
            else {
               this.$.seriesDescTooltip.playAnimation("exit");
            }
         }// for i

      }// if

      if (bRedraw) {
         this.drawBoard();
      }
   }

   SetMouseDown_SelectCell(nX, nY, nBtn) {
      if (nBtn === 1) {
         this.SetMouseMove_SelectCell(nX, nY, nBtn);

         if (this.Info.SelectCell.SelectedRow > -1 && this.Info.SelectCell.SelectedColumn > -1) {

            this.Info.EditMode = this.e_EditMode_SetPosition;
            this.Info.SetPosition.Rows = this.Info.SelectCell.SelectedRow + 1;
            this.Info.SetPosition.Columns = this.Info.SelectCell.SelectedColumn + 1;
            this.setSetPositionCellBounds();
            this.drawBoard();
         }
      }// if
   }

   SetMouseDown_SetPosition(nX, nY, nBtn) {
      if (nBtn === 1) {
         for (let i = 0; i < this.Info.SetPosition.Cells.length; i++) {
            const oCell = this.Info.SetPosition.Cells[i];

            const nL = oCell.Position.X;
            const nT = oCell.Position.Y;
            const nW = oCell.Size.Width;
            const nH = oCell.Size.Height;
            const nR = nL + nW;
            const nB = nT + nH;

            this.g_thumbnailArea.nL = nL;
            this.g_thumbnailArea.nR = nR;
            this.g_thumbnailArea.nT = nT;
            this.g_thumbnailArea.nB = nB;

            if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
               this.Info.MouseDownCellIndex = i;
               break;
            }// if
         }// for i
      }
   }

   SetMouseOver_SetTooltip(si, ii) {
      if(this._thumbnail.series && this._thumbnail.series[si] && this._thumbnail.series[si].image && this._thumbnail.series[si].image[ii]) {
         // this.seriesDesc = this._thumbnail.series[si].image[ii].seriesDescription;
         const {seriesDescription} = this._thumbnail.series[si].image[ii];
         this.seriesDesc = seriesDescription || (this._thumbnail.series[si].seriesDescription || "");

         if (this.seriesDesc === "hie_unknown") {
            this.seriesDesc = "-";
         }

         this.$.seriesDescTooltip.playAnimation("entry");
      }
      else {
         this.$.seriesDescTooltip.playAnimation("exit");
      }
   }

   nextImages() {
      if(this._nextImage){

         this._prevImage = true;

         let nPos = 0;

         // 셀 갯수
         const nCellLen = this._infoSize.Position.length;
         // 총 시리즈 갯수
         const nSeriesLen = this._arrImage.length - 1;

         // eslint-disable-next-line guard-for-in
         for(const i in this._infoSize.Position) {
            // 다음 페이지에서 시작되는 시리즈 index
            const nextSeries = this._nextIdx+nCellLen;

            if(nextSeries === nSeriesLen){
               this._nextImage = false;
            }

            if (nSeriesLen >= nextSeries) {
               this._infoSize.Position[nPos].Cell = this._arrImage[nextSeries];
            }
            else {
               this._nextImage = false;
               this._infoSize.Position[nPos].Cell = null;
            }

            nPos++;
            this._nextIdx++;
         }

         this.clearCanvas();
         this.drawImages();

      } // if
   }

   prevImages() {
      if(this._prevImage){

         let nSeriesLen = this._arrImage.length;
         const nCellLen = this._infoSize.Position.length;
         const emptySeries = nSeriesLen-nCellLen;

         // 코드 1391번 줄에서 증가를 하고  루프문에서 빠져나오기 때문에 -1을 해줘야함
         this._nextIdx-=1;

         // 남은 시리즈가 있는지 확인
         if(emptySeries > 0) {
            nSeriesLen =  nSeriesLen-emptySeries-1;
            this._nextImage = true;
         }
         else {
            this._prevImage = false;
         }

         // eslint-disable-next-line guard-for-in
         for(const nPos in this._infoSize.Position) {

            if(this._nextIdx >= 0) {
               this._infoSize.Position[nSeriesLen-parseInt(nPos, 10)].Cell = this._arrImage[this._nextIdx];
            }
            else {
               this._prevImage = false;
               break;
            }

            this._nextIdx--;

         }

         this.clearCanvas();
         this.drawImages();

         // 코드 1432번 줄에서 감소를 하고  루프문에서 빠져나오기 때문에 +1을 해줘야함
         this._nextIdx+=1;

      } // if
   }

   getThumbnailCell(checkSet) {
      this._checkThumbnailCell = checkSet;
   }

   setThumbnailCell() {
      this.thumbnailCell = true;

      const param = {};
      param.detail = this.thumbnailCell;

      this.getThumbnailCell(param.detail);
      this.startBody();

      param.detail = false;
      this.getThumbnailCell(param.detail);
   }

   isUseCache() {
      // return (window.caches && !JSON.parse(localStorage.getItem("stopPrefetch")));
      return true;
   }

   setLoadingImage() {
      fetch(`/resource/images/loading-image.png`).then((response) => {
         if(response.status === 200) {
            response.blob().then((blob) => {
               // console.log("loading-image", blob);
               this.loadingImage = (window.URL || window.webkitURL).createObjectURL(blob);
            });
         } else {
            console.error(response);
         }
      });
   }

   setNotfoundImage() {
      fetch(`/resource/images/notfound-image.png`).then((response) => {
         if(response.status === 200) {
            response.blob().then((blob) => {
               // console.log("notfound-image", blob);
               this.notfoundImage = (window.URL || window.webkitURL).createObjectURL(blob);
            });
         } else {
            console.error(response);
         }
      });
   }

   fetchThumbnail(id) {
      this.getCaseByIdWithKeyImage(id).then((resultThumbnail) => {
         this.thumbnailImage = { id, image: resultThumbnail };
      });
   }

   getCaseByIdWithKeyImage(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/thumbnail`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse.exam[0]);
               });
            }
            // TODO 예외처리 방안
            else {
               console.log("fail");
               reject(new Error("fail"));
            }
         });
      });
   }
}

window.customElements.define(HhThumbnail.is, HhThumbnail);
