import { PolymerElement, html } from "@polymer/polymer/polymer-element";
import Prefetch from "hh-prefetch";
import i18next from "i18next";
import mixinCommons from "../common-mixin";

import "@polymer/paper-item";
import "@polymer/iron-meta"; import "@polymer/paper-item/paper-icon-item";
import "@polymer/paper-tooltip/paper-tooltip";
import "@polymer/paper-toggle-button";
import "@polymer/paper-icon-button/paper-icon-button";
import "@polymer/iron-icon";
import "@polymer/iron-icons/notification-icons";
import "@vaadin/vaadin-icons/vaadin-icons";
import "@vaadin/vaadin-progress-bar/vaadin-progress-bar";
import "@hpacs/healthhub-icons/healthhub-icons";

import "./hh-filesystem-setting-dialog";
import "../components/healthhub-confirm-dialog";
import store from "../redux/store";
import {CommonActionType, DialogActionType, DialogType} from "../redux/reducers/common";
import i18n from "../utils/i18n-utils";

class HHFilesystem extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-filesystem";
   }

   static get template() {
      return html`
      <style>
         :host{
            display: inline;
            background-color: #1d1e25;
            color: #ccc;
            width: 100%;
            height: 100%;
         }

         .container {
            display: flex;
            align-items: center;
            width: 380px;
            justify-content: space-evenly;
         }

         paper-icon-button {
            width: 24px;
            height: 24px;
            padding: 0px;
         }

         .progress-bar{
            height: 16px;
            width: 80px;
            /* margin : 5px 5px 0 0; */
            background-color: #292839;
         }

         iron-icon{
            width: 15px;
            /* height: 29px; */
            vertical-align: center;
            /* margin-right: 5px; */
         }

         .storage-used{
            /* display: inline-flex; */
            color: #ccc;
            font-size: 14px;
            /* font-weight: bold; */
            /* padding-left: 10px; */
            /* padding-right: 12px; */
            /* width: 100px; */
         }

         .progress-info{
            display: flex;
            align-items: center;
            --paper-tooltip: {
               font-size: 15px;
            }
         }

         paper-icon-item {
            /* 메뉴아이콘아이템 높이 */
            --paper-item-min-height: 31px;
         }
         paper-icon-button {
            color: #fff;
            width: 15px;
            height: 100%;
            /* margin-right: 5px; */
         }
         settingBtn{
            visibility: hidden;
         }

         /*
         .prefetch-icon {
            cursor: pointer;
            width: 20px;
            height: 20px;
         }

         .prefetch-on {
            color: #0087cb;;
         }

         .prefetch-off {
            color: #ec971f;
         }
         */

         paper-toggle-button.prefetch-icon {
            cursor: pointer;
            --paper-toggle-button-unchecked-bar-color: #a8a8a8;
            --paper-toggle-button-unchecked-button-color: #a8a8a8;
         }

         paper-tooltip  {
            --paper-tooltip-duration-in: 0;
            --paper-tooltip-duration-out: 0;
         }
      </style>
      <div class="container">
         <!-- <paper-icon-item> -->
         <span class="storage-used">{{t("label.prefetch")}}</span>
         <paper-toggle-button id="prefetch-icon" class="prefetch-icon"
            checked="{{pfStatus.checked}}" disabled="{{pfStatus.disabled}}"
            on-change="togglePrefetch"></paper-toggle-button>
         <!-- <div class="progress-info"> -->
         <iron-icon class="icon-database" icon="vaadin:database"></iron-icon>
         <vaadin-progress-bar class="progress-bar" id="progressBarValue" min="0" max="100"></vaadin-progress-bar>
         <span class="storage-used" id="storageUsedValue">0</span>
         <!-- </div> -->

         <paper-icon-button id="clearBtn" icon="vaadin:trash"></paper-icon-button>
         <paper-icon-button id="settingBtn" icon="vaadin:cog" style="display: none">
            <!-- <paper-icon-button id="settingBtn" icon="vaadin:cog"> -->
         </paper-icon-button>

         <!-- </paper-icon-item> -->
         <paper-tooltip for="progressBarValue" offset="0" animation-delay="0" id="storageInfo"></paper-tooltip>
         <paper-tooltip for="settingBtn" offset="0" animation-delay="0" >Setting file system</paper-tooltip>
         <paper-tooltip for="clearBtn" offset="0" animation-delay="0" >{{t("button.prefetch.clean")}}</paper-tooltip>
         <paper-tooltip id="prefetchTooltip" for="prefetch-icon" offset="0" animation-delay="0">{{pfStatus.title}}</paper-tooltip>
      </div>
      <hh-filesystem-setting-dialog id="hhfilesystemSettingDialog" ></hh-filesystem-setting-dialog>
<!--      <hh-confirm-dialog id="hhConfirmDialog"></hh-confirm-dialog>-->
      `;
   }

   static get properties() {
      return {
         // _storage: {
         //    type: String
         // },
         _flagStorage: {
            type: Boolean,
            value: false
         },
         // name:{
         //    type: String
         // },
         // storage:{
         //    type: String
         // },
         prefetch: {
            type: Object
         },
         pfStatus: {
            type: Object
         }
      };
   }

   constructor() {
      super();

      const disabled = !(window.caches);
      const bol = JSON.parse(localStorage.getItem("stopPrefetch"));
      const checked = !(bol != null && bol === true);

      let title;
      if(disabled) title = i18next.t("button.prefetch.disabled");
      else if(checked) title = i18next.t("button.prefetch.off");
      else title = i18next.t("button.prefetch.on");

      this.pfStatus = { checked, disabled, title };
   }

   ready() {
      super.ready();

      window.addEventListener("changeCacheUsageEvent", () => {
         this.currentUsageFileSystem();
      });
      // changeCacheUsageEvent

      this.prefetch = Prefetch.create({
         numImageWorkers: 1,
      });

      // window.requestFileSystem  = window.requestFileSystem || window.webkitRequestFileSystem;
      // window.directoryEntry = window.directoryEntry || window.webkitDirectoryEntry;
      this.currentUsageFileSystem();
      this.$.settingBtn.addEventListener("click", () => {
         this.$.hhfilesystemSettingDialog.doOpen();
      });

      this.$.clearBtn.addEventListener("click", () => {
         const message = {
            contents: i18n("msg.prefetch.confirmClean", { returnObjects: true }),
            title: i18n("button.prefetch.clean"),
            ok: i18n("button.accept"),
            cancel: i18n("button.cancel"),
            onOk: () => this.clearFileSystem(),
         };
         // this.$.hhConfirmDialog.doCustomSizeOnlyWidth(420);
         // this.$.hhConfirmDialog.doOpen(message);
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.PREFETCH_CLEAR, message, open: true } });
      });

      // this.$.hhConfirmDialog.addEventListener("confirm", () => {
      //    this.clearFileSystem();
      // });

      this.$.hhfilesystemSettingDialog.addEventListener("deletedRawCaseIDEvent", (e) => {
         this.dispatchEvent(new CustomEvent("deletedRawCaseIDEvent", e));
      });

      this.togglePrefetch();

   } // ready

   /**
    * 파일 시스템 초기화 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:15
    */
   initFileSystem() {
      // window.requestFileSystem(window.TEMPORARY, 1024 * 1024, (fs) => {
      //    fs.root.getDirectory("raw", { create: true }, (dirEntry) => {
      //       console.log(dirEntry);
      //    }, console.log("error"));

      //    fs.root.getDirectory("thumbnail", { create:true }, (dirEntry) => {
      //       console.log(dirEntry);
      //    }, console.log("error"));
      // });
   }

   /**
    * 파일시스템 용량 확인 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:15
    */
   currentUsageFileSystem(){
      this.prefetch.getUsage().then((data) => {
         const total = Math.ceil(data.quota.bytes / (1024 * 1024)) / 1000; // 전체용량
         const cUsage = Math.ceil(data.usage.bytes / (1024 * 1024)) / 1000; // 사용량
         const roudTotal = total.toFixed(1); // 전체용량 소수점 첫째자리 반올림
         const roundUsage = cUsage.toFixed(1); // 전체용량 소수점 첫째자리 반올림
         // _storage = data.ratio * 100; //사용량 퍼센트 계산
         // _storage = Math.ceil(_storage); //사용량 퍼센트 계산
         const storage = Math.ceil(data.ratio);

         this.$.storageUsedValue.innerHTML = `${roundUsage}GB / ${roudTotal}GB`;
         this.$.progressBarValue.value = storage;
         this.$.storageInfo.innerHTML = `${i18next.t("label.totalStorage")} : ${total}GB <br />`;
         this.$.storageInfo.innerHTML += `${i18next.t("label.usingStorage")} : ${cUsage}GB`;

         // console.log(storage +"    "+ this._flagStorage);

         // 일정 용량이 차면 오늘날자 이전의 케이스파일은 삭제 (70%)
         if(storage >= 70 && !this._flagStorage) {
            this._flagStorage = true;
            this.$.hhfilesystemSettingDialog.checkFileSystemDir();
         } else if(storage < 70) {
            this._flagStorage = false;
         }
      }).catch((error) => {
         console.error(error);
         const detail = {msg: error.message, isErr: true};
         this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
      });

      // window.webkitStorageInfo.queryUsageAndQuota(TEMPORARY, (usage, quota) => {
      //    const total = Math.ceil(quota / (1024 * 1024)) /1000; // 전체용량
      //    const cUsage = Math.ceil(usage / (1024* 1024)) /1000; // 사용량
      //    const roudTotal = total.toFixed(1); // 전체용량 소수점 첫째자리 반올림
      //    const roundUsage = cUsage.toFixed(1); // 전체용량 소수점 첫째자리 반올림
      //    this._storage = (usage / quota) * 100; //사용량 퍼센트 계산
      //    this._storage = Math.ceil(this._storage); //사용량 퍼센트 계산

      //    this.$.storageUsedValue.innerHTML = `${roundUsage}GB / ${roudTotal} GB`;
      //    this.$.progressBarValue.value = this._storage;
      //    this.$.storageInfo.innerHTML = `Total storage : ${total}GB <br />`;
      //    this.$.storageInfo.innerHTML += `Using storage : ${cUsage}GB`;

      //    // console.log(_storage +"    "+_flagStorage);

      //    // 일정 용량이 차면 오늘날자 이전의 케이스파일은 삭제 (70%)
      //    if(this._storage >= 70 && !this._flagStorage){
      //       this._flagStorage = true;
      //       this.$.hhfilesystemSettingDialog.checkFileSystemDir();
      //    } else if(this._storage < 70){
      //       this._flagStorage = false;
      //    }
      // }, (e) => {
      //    console.log("Error", e);
      // });
   }

   /**
    * raw 이미지 경로 삭제 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:17
    */
   clearRawDirectory(){
      // fs.root.getDirectory("raw", { create: true }, (dirEntry) => {
      //    if(dirEntry){
      //       dirEntry.removeRecursively(() => {
      //          console.log("raw folder remove");
      //          this.currentUsageFileSystem();
      //       });
      //    }
      // });
   }

   /**
    * thumbnail 이미지 경로 삭제 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:18
    */
   clearThunmbnailDirectory(){
      // fs.root.getDirectory("thumbnail", { create: true }, (dirEntry) => {
      //    if(dirEntry){
      //       dirEntry.removeRecursively(() => {
      //          console.log("thumbnail folder remove");
      //          this.currentUsageFileSystem();
      //       });
      //    }
      // });
   }

   /**
    * 파일시스템 모든 파일 삭제 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:18
    */
   clearFileSystem() {
      this.prefetch.clearStorage().then(() => window.location.reload());
      /*
      window.requestFileSystem(window.TEMPORARY, 1024 * 1024, function (fs) {
         let dirReader = fs.root.createReader();
         let entries = [];
         let clearCnt = 0;
         let fetchEntries = function() {
            dirReader.readEntries(function(results) {
               entries = results;
               if(entries.length > 0){
                     for(let i=0; i<entries.length; i++){
                        if(entries[i].isDirectory){
                           console.log(entries[i]);
                           entries[i].removeRecursively(function () {
                                 _this.currentUsageFileSystem();
                           })
                        }
                        if(clearCnt == (entries.length -1)){
                           window.location.reload();
                        }else{
                           clearCnt++;
                        }
                     }
               }else{
                     console.log("Directory is not");
               }
            }, _this.errorHandler);
         };
         fetchEntries();
      });
      */
   }

   // changeCacheUsage() {
   //    this.currentUsageFileSystem();
   // }

   togglePrefetch() {
      if(!this.pfStatus.disabled) {
         if(this.pfStatus.checked) {
            this.pfStatus.title = i18next.t("button.prefetch.off");
            localStorage.removeItem("stopPrefetch");
         } else {
            this.pfStatus.title = i18next.t("button.prefetch.on");
            localStorage.setItem("stopPrefetch", true);
         }

         this.$.prefetchTooltip.innerHTML = this.pfStatus.title;

         store.dispatch({ type: CommonActionType.TOGGLE_PREFETCH, payload: this.pfStatus.checked });

         const detail = { prefetch: this.pfStatus.checked };
         this.dispatchEvent(new CustomEvent("TOGGLE_PREFETCH", {bubbles: true, composed: true, detail}));
      }
   }
}

window.customElements.define(HHFilesystem.is, HHFilesystem);
