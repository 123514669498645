import { PolymerElement, html } from "@polymer/polymer/polymer-element";

import "@polymer/paper-button/paper-button";
import "@polymer/paper-dialog/paper-dialog";

import { is_esc } from "../dialog/common-keyboard-handler";

class HHFilesystemDialog extends PolymerElement {
   static get is() {
      return "hh-filesystem-setting-dialog";
   }

   static get template() {
      return html`
      <style>
         :host {
            position: fixed;
            top: 16px;
            right: 16px;
         }

         .class-filesystemSetting {
            height: 100%;
         }

         paper-dialog {
            width: 400px;
            height: 100px;
            background-color: #394451;
            z-index: 1;
         }

         #okBtn {
            background: #0087cb;
         }

         #cancelBtn {
            background: #4c5667;
         }

         paper-button {
            width: 122px;
            height: 35px;
            border: 0;
            font-size: 10pt;
            color: #ccc;
            cursor: pointer;
            border-radius: 2px;
         }

         .class-btn {
            height: 20%;
            width: 100%;
         }
      </style>
      <paper-dialog id="filesystemSetting" entry-animation="scale-up-animation" exit-animation="fade-out-animation" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-focus="focus_on_selected_button" on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed" alwaysOnTop>
         <div class="class-filesystemSetting">
            <div class="class-btn">
               <paper-button id="okBtn" dialog-confirm autofocus>Test</paper-button>
               <paper-button id="cancelBtn" dialog-dismiss>Cancel</paper-button>
            </div>
         </div>
         <ul id="filelist"></ul>
      </paper-dialog>
      `;
   }

   static get properties() {
      return {
      };
   }

   ready() {
      super.ready();

      // 파일시스템 호출
      window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
      window.directoryEntry = window.directoryEntry || window.webkitDirectoryEntry;

      this.$.okBtn.addEventListener("click", () => {
         this.checkFileSystemDir();
      });
   }

   close_if_esc(e) {
      e.stopPropagation();
      if(is_esc(e)) {
         this.doClose();
      }
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      window.dialog_opened({dialog:this.$.filesystemSetting, is_modal:true});
   }

   dialog_closed() {
      window.dialog_closed();
   }


   /**
    * 파일시스템 최상단(ROOT) 경로 호출 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:28
    */
   checkFileSystemDir() {
      window.requestFileSystem(window.TEMPORARY, 1024 * 1024, (fs) => {
         const dirReader = fs.root.createReader();
         let entries = [];
         const readEntries = () => {
            dirReader.readEntries((results) => {
               if (!results.length) {
                  this.listResults(entries.sort());
               } else {
                  entries = entries.concat(this.toArray(results));
                  readEntries();
               }
            });
         };
         readEntries(); // Start reading dirs.
      });
   }

   /**
    * 해당 경로 파일,디렉토리 호출 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:27
    */
   listResults(entries) {
      entries.forEach((entry) => {
         const subDirReader = entry.createReader();
         let subEntries = [];
         const subReadEntries = () => {
            subDirReader.readEntries((results) => {
               if (!results.length) {
                  this.subListResults(subEntries.sort());
               } else {
                  subEntries = subEntries.concat(this.toArray(results));
                  subReadEntries();
               }
            });
         };

         subReadEntries(); // Start reading dirs.
      });
   }

   /**
    * 하위 경로 엔트리 리스트 호출 메소드
    * 재귀함수 호출
    *
    * Create by BohyunJang on 2018-12-28 오후 3:27
    */
   subListResults(entries) {
      let cntEntry = null;
      entries.forEach((entry, i) => {
         if (entries[0].fullPath.includes("raw")) {
            cntEntry = entries.length;
         }

         entries[i].getMetadata((metaData) => {
            const rDate = this.getFormatDate(metaData.modificationTime);
            let today = new Date();
            today = this.getFormatDate(today);

            if (cntEntry != null) {
               cntEntry--;
            }

            if (rDate !== today) {
               this.deleteDirOtherDay(entries[i]);
            }

            if (entries[i].fullPath.includes("raw") && (cntEntry === 0)) {
               // return;
            }
         });
      });
   }

   /**
    * 엔트리 리스트의 파일 이름 추출 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:29
    */
   toArray(list) {
      return Array.prototype.slice.call(list || [], 0);
   }

   /**
    * 해당 경로 삭제 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:26
    */
   deleteDirOtherDay(entry) {
      const caseID = entry.name;
      entry.removeRecursively(() => {
         const detail = { // new Object();
            data: caseID,
            msgType: "none",
            target: "newFilm"
         };

         const param = {
            detail
         }; // new Object();

         this.dispatchEvent(new CustomEvent("deletedRawCaseIDEvent", param));
      });
   }

   /**
    * Date 형식 yyyyMMdd 포맷으로 반환 메소드
    *
    * Create by BohyunJang on 2018-12-28 오후 3:24
    */
   getFormatDate(date) {
      const year = date.getFullYear();           // yyyy
      let month = (1 + date.getMonth());         // M
      let day = date.getDate();                  // d
      month = month >= 10 ? month : `0${ month}`; // month 두자리로 저장
      day = day >= 10 ? day : `0${day}`;         // day 두자리로 저장
      return `${year}${month}${day}`;
   }

   /**
    * dialog open
    *
    * Create by BohyunJang on 2018-12-19 오전 9:17
    */
   doOpen() {
      this.$.filesystemSetting.open();
   }

   /**
    * dialog 닫기 메소드
    *
    * Create by BohyunJang on 2018-12-18 오후 6:28
    */
   doClose() {
      this.$.filesystemSetting.close();
   }
}

window.customElements.define(HHFilesystemDialog.is, HHFilesystemDialog);
