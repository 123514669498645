/* eslint-disable no-bitwise,no-param-reassign,object-shorthand */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/polymer/lib/elements/dom-repeat";

import "@polymer/paper-icon-button/paper-icon-button";
import "@polymer/paper-dialog/paper-dialog";
import "@polymer/paper-checkbox/paper-checkbox";
import "@polymer/paper-button/paper-button";
import "@polymer/paper-input/paper-input";

import "@polymer/iron-autogrow-textarea/iron-autogrow-textarea";
import "@polymer/iron-icon";
import "@polymer/iron-icons";

import "@vaadin/vaadin-icons/vaadin-icons";

import "@hpacs/healthhub-icons/healthhub-icons";
import mixinCommons from "../common-mixin";
import {CommonActionType, CustomContextMenuType} from "../redux/reducers/common";
import store from "../redux/store";

class HHTechReport extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-tech-report";
   }

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .container {
               display: flex;
               flex-direction: column;
               height: 100%;
               width: 100%;
            }

            .textarea-container {
               background-color: rgba(0, 0, 0, 0.30);
               display: flex;
               padding: 5px;
               height: calc(100% - 40px);
            }

            iron-autogrow-textarea {
               --iron-autogrow-textarea : {
                  padding: 0px;
               }
               width: 100%;
               color: #ccc;
               padding: 0px;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 15px;
               line-height: 1.5em;
               font-family: NotoSansCJK, NotoSansCJKLight;
            }

            textarea {
               height: calc(100% - 23px);
               width: calc(100% - 20px);
               background-color: rgba(0, 0, 0, 0); /* 투명 (opacity: 0) */
               color: #ccc;
               padding: 0px;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 15px;
               line-height: 1.5em;
               font-family: NotoSansCJK, NotoSansCJKLight;
            }

            .readOnly {
               color: #999;
            }

            textarea::placeholder {
               color: #444;
            }

            paper-input {
               --paper-input-container-input-color: #fff;
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  font-size: 14px;
               };
               width: 150px;
            }

            iron-icon, div[suffix] {
               color: hsl(0, 0%, 50%);
               margin-right: 12px;
               width: 14px;
               height: 14px;
            }

            .ag-row-selected {
               background-color: #596072 !important;
               color: #fff !important;
            }

            #scpUsersModal {
               width: 200px;
               border-radius: 5px;
               margin: 0;
               border: 2px solid;
               border-color: #0087cb;
               color: #0087cb;
               top: 264px;
            }

            paper-listbox {
               padding: 0px;
               margin: 0px;
            }

            paper-item {
               font-size: 14px;
               cursor: pointer;
            }

            paper-item:hover {
               font-weight: bold;
               background-color: #0087cb;
               color: #fff;
            }

            .accordion {
               background-color: #252934;
               box-shadow: 0px 3px 9px -2px #000000;
               font-size: 14px;
               color: #aaaaaa;
               cursor: pointer;
               margin: 5px 0 5px 0;
               padding: 0 10px 0 10px;
               height: 30px;
               width: 100%;
               border: none;
               text-align: left;
               outline: none;
               transition: 0.4s;
            }

            .active, .accordion:hover {
               background-color: #0087cb;
               color: #f0f0f0;
               font-weight: bold;
            }

            .accordion:after {
               content: '\\002B';
               color: #f0f0f0;
               font-weight: bold;
               float: right;
               margin-left: 5px;
            }

            .active:after {
               content: "\\2212";
            }

            .panel {
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 0 5px;*/
               color: #aaaaaa;
               display: none;
               /* background-color: #38528929; */
               box-shadow: 0px 3px 9px -2px #000000;
               overflow-x: hidden;
               transition: max-height 0.2s ease-out;
            }
            .class-newAddendum{
               min-height: 64px;
               width: calc(100% - 20px);
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 0 10px;*/
               color: #aaaaaa;
               display: none;
               background-color: rgba(0, 0, 0, 0.10);
               overflow-x: hidden;
               overflow-y: auto;
               transition: max-height 0.2s ease-out;
            }

            .approve-panel {
               /*margin: 1px 5px 5px 5px;*/
               /*padding: 5px;*/
               color: #aaaaaa;
               display: none;
               /*background-color: #111c3329;*/
               /*box-shadow: 0px 3px 9px -2px #000000;*/
               overflow: hidden;
               transition: max-height 0.2s ease-out;
               padding: 0 5px 5px 5px;
            }

            .class-report-approve-title {
               height: 30px;
               display:flex;
               align-items: center;
               padding-left: 10px;
               background-color: #252934;
               font-weight: bold;
               font-size: 12px;
               box-shadow: 0px 3px 9px -2px #000000;
               color: white;
               font-size: 13px;
               margin: 5px 0 5px 0;
            }

            .class-report-approve-title-content {
               width: 100%;
               font-size: 13px;
               font-weight: bold;
               background-color: rgba(0, 0, 0, 0.10);
               color: #aaa;
               border: 0px;
            }

            .class-report-approve-content {
               height: 100%;
            }

            .report-approve-content {
               height: 100%;
               width: 100%;
               background-color: #111c3329;
            }

            .class-addendum-report-content {
               height: 100%;
               width: 100%;
               overflow-y: auto;
               overflow-x: hidden;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            /* context menu */
            .ag-theme-balham-dark .ag-popup-child:not(.ag-tooltip-custom) {
               box-shadow: 5px 5px 10px rgba(0,0,0,.3);
            }
            .ag-theme-balham-dark .ag-menu {
               border: 1px solid;
               border-color: #424242;
               background: #2d3436;
               border-radius: 2px;
               box-shadow: none;
               padding: 4px;
               padding: 0;
            }

            .ag-theme-balham-dark [class^=ag-],
            .ag-theme-balham-dark [class^=ag-]:after,
            .ag-theme-balham-dark [class^=ag-]:before,
            .ag-theme-balham-dark [class^=ag-]:focus {
               box-sizing: border-box;
               outline: none;
            }

            .ag-ltr {
               direction: ltr;
            }

            .ag-menu {
               max-height: 100%;
               overflow-y: auto;
               position: absolute;
               /* left: 100px; */
               /* top: 100px; */
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-popup-child {
               z-index: 3;
            }

            .ag-theme-balham-dark {
               -webkit-font-smoothing: antialiased;
               color: #f5f5f5;
               font-size: 12px;
               line-height: normal;
            }

            .ag-unselectable {
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-root-wrapper {
               cursor: default;
               position: relative;
               display: flex;
               flex-direction: column;
               overflow: hidden;
            }
            .ag-theme-balham-dark .ag-menu-list {
               cursor: default;
               width: 100%;
               padding-top: 4px;
               padding-bottom: 4px;
            }

            .ag-menu-list {
               display: table;
            }

            .ag-ltr {
               direction: ltr;
            }

            .ag-menu {
               max-height: 100%;
               overflow-y: auto;
               position: absolute;
               -webkit-user-select: none;
               -ms-user-select: none;
               user-select: none;
            }

            .ag-menu-option,
            .ag-menu-separator {
               display: table-row;
            }

            .ag-menu-option {
               cursor: pointer;
            }

            .ag-menu-option:not(.ag-menu-option-disabled):hover {
               background: #3d4749;
            }

            .ag-theme-balham-dark .ag-menu-option-icon {
               /* padding-left: 8px; */
               /* padding-right: 4px; */
               /* min-width: 24px; */
               text-align: center;
               color: #f5f5f5;
            }

            iron-icon {
               --iron-icon-fill-color: #f5f5f5;
            }

            .ag-theme-balham-dark .ag-menu-option-part {
               padding-top: 6px;
               padding-bottom: 6px;
            }

            .ag-menu-option-part,
            .ag-menu-separator-cell {
               display: table-cell;
               vertical-align: middle;
            }

            .ag-theme-balham-dark .ag-menu-list {
               cursor: default;
               width: 100%;
               padding-top: 4px;
               padding-bottom: 4px;
            }

            .ag-theme-balham-dark .ag-menu-option-disabled {
               opacity: .5;
               cursor: default;
            }

            .ag-menu-option-part, .ag-menu-separator-cell {
               display: table-cell;
               vertical-align: middle;
            }

            .ag-theme-balham-dark .ag-menu-separator {
               height: 8px;
            }

            .ag-theme-balham-dark .ag-menu-separator-cell:after {
               content: "";
               display: block;
               border-top: 1px solid;
               border-top-color: #424242;
               border-top-color: var(--ag-border-color,#424242);
            }

            #contextMenu {
               position: absolute;
            }

            .hideContextMenu {
               display: none;
            }

            .opinion-title {
               height: 30px;
               display:flex;
               align-items: center;
               /*margin:5px 0 5px 0;*/
               padding-left: 10px;
               background-color: #252934;
               font-weight: bold;
               box-shadow: 0px 3px 9px -2px #000000;
               color: #aaaaaa;
               font-size: 13px;
            }

            .opinion-content {
               white-space: pre-wrap;
               /*margin: 0 0 15px 5px;*/
               /* background-color: #2b3445; */
               background-color: rgba(0, 0, 0, 0.10);
               color: #ccc;
               min-height: 27px;
               padding: 10px;
               word-break: break-all;

               -webkit-user-select: text;   /* Safari */
               -khtml-user-select: text;    /* Konqueror HTML */
               -moz-user-select: text;      /* Firefox */
               -ms-user-select: text;       /* Internet Explorer/Edge */
               user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
            }

            .addendum-content {
               white-space: pre-wrap;
               /*margin: 0 0 15px 5px;*/
               /* background-color: #2b3445; */
               background-color: rgba(0, 0, 0, 0.10);
               color: #ccc;
               /*min-height: 50px;*/
               padding: 10px;

               -webkit-user-select: text;   /* Safari */
               -khtml-user-select: text;    /* Konqueror HTML */
               -moz-user-select: text;      /* Firefox */
               -ms-user-select: text;       /* Internet Explorer/Edge */
               user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
            }

            button.accordion {
               -webkit-user-select: text;   /* Safari */
               -khtml-user-select: text;    /* Konqueror HTML */
               -moz-user-select: text;      /* Firefox */
               -ms-user-select: text;       /* Internet Explorer/Edge */
               user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
            }

            button.accordion.active,
            button.accordion:hover {
               background-color: #252934;
            }

            .report-container {
               display: flex;
               flex-direction: column;
               padding-top: 5px;
            }

            .input-resizer {
               box-sizing: border-box;
               resize: none;
               overflow: hidden;
               width: 100%;
               height: 100%;
            }
         </style>

         <div class="container">
            <div id="reportContents" class="class-addendum-report-content">
               <!--Start of test accodian-->
               <div class ="class-addendumContent" id="addendumContent" style="display: block;">
                  <button class="accordion" id="approveInfo" active></button>
                  <div class="approve-panel" id="approvePanel">
                     <div class="report-approve-content">
                        <div style="height: 100%; width: 100%;">
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.findings")}}</div>
                              <div class="context-menus opinion-content">{{findings}}</div>
                           </div>
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.conclusion")}}</div>
                              <div class="context-menus opinion-content">{{conclusion}}</div>
                           </div>
                           <div>
                              <div style="height: 30px; display:flex; align-items: center; padding-left: 10px;background-color: #252934;font-weight: bold;font-size: 12px;box-shadow: 0px 3px 9px -2px #000000; color: #aaaaaa; font-size: 13px;">{{t("label.recommendation")}}</div>
                              <div class="context-menus opinion-content">{{recommendation}}</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!--addendum list-->
                  <div id="divAddendumList">
                     <template is="dom-repeat" items="[[addendumList]]" id="addendumRepeat" on-dom-change="attachContextMenu">
                        <button class="accordion active">[[item.title]]</button>
                        <div class="panel" style="display: block;">
                           <div class="context-menus addendum-content">[[item.addendum]]</div>
                        </div>
                     </template>
                  </div>
               </div>
               <!--End of test accodian-->
            </div>

            <!--contextMenu-->
            <div id="contextMenu" class="contextMenu hideContextMenu">
               <div class="ag-theme-balham-dark ag-popup">
                  <div class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus" style="min-width: 125px;">
                     <div class="ag-menu-list" tabindex="-1">
                        <div class="ag-menu-option do-copy" tabindex="-1" on-click="handleCopy">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-copy"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.copy")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-select-all" tabindex="-1" on-click="handleSelectAll">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:select-all"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.selectAll")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-separator">
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                           <span class="ag-menu-separator-cell"></span>
                        </div>
                        <div class="ag-menu-option do-copy-report" tabindex="-1" on-click="handleCopyReport">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:description"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.copyReport")}}</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         findings: {
            type: String,
            value: ""
         },
         conclusion: {
            type: String,
            value: ""
         },
         recommendation: {
            type: String,
            value: ""
         },
         newAddendum:{
            type:String,
            value: ""
         },
         addendumList : {
            type: Array,
            value: []
         },
         contextWidth: {
            type: Number,
            value: 125
         },
         contextHeight: {
            type: Number,
            value: 110
         },
         elemEditor: {
            type: Object
         },
         opinion : {
            type: Object,
            value: {}
         },
         clipboard: {
            type: Object
         },
         supportClipboard: {
            type: Boolean,
            value: false
         },
         fHeight: {
            type: Number
         },
         rfTopHeight: {
            type: String,
            value: "33.3%"
         },
         rfBottomHeight: {
            type: String,
            value: "66.7%"
         },
         rfTopMinHeight: {
            type: String
         },
         rfBottomMinHeight: {
            type: String
         },
         rsTopHeight: {
            type: String,
            value: "50%"
         },
         rsBottomHeight: {
            type: String,
            value: "50%"
         },
         rsTopMinHeight: {
            type: String
         },
         rsBottomMinHeight: {
            type: String
         },
         customContextMenuState: {
            type: Number,
            observer: "changeCustomContextMenuState"
         },
         customUniqueKey: {
            type: String,
            readonly: true,
            computed: "computeCustomUniqueKey()"
         }
      };
   }

   computeCustomUniqueKey() {
      return `${CustomContextMenuType.TECH_REPORT}_${Math.random()}`;
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.customContextMenuState = store.getState().common.customContextMenu;
      });

      this.$.addendumContent.addEventListener("click", (e) => {
         if(e.path[0].nodeName === "BUTTON"){
            e.target.classList.toggle("active");
            if(e.target.nextElementSibling){
               const panel = e.target.nextElementSibling;
               if (panel.style.display === "block") {
                  panel.style.display = "none";
               } else {
                  panel.style.display = "block";
                  window.scroll({
                     top: 100,
                     left: 100,
                     behavior: "smooth"
                  });
               }
            }
         }
      });

      this.supportClipboard = navigator.clipboard;

      this.$.contextMenu.style.width  = `${this.contextWidth}px`;
      this.$.contextMenu.style.height = `${this.contextHeight}px`;

      // show context menu
      this.shadowRoot.querySelectorAll(".context-menus").forEach(el => this.setContextMenu(el));
      document.addEventListener("click", () => {
         // if(!this.$.contextMenu.classList.contains("hideContextMenu"))
         //    this.$.contextMenu.classList.add("hideContextMenu");
         // console.log("[tech-report] document click", (this.customContextMenuState))
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });
   } // ready

   initTextArea() {
      this.findings = "";
      this.conclusion = "";
      this.recommendation = "";
      this.newAddendum = "";
   }

   getOpinion() {
      const opinion = {};
      opinion.finding = this.findings;
      opinion.conclusion = this.conclusion;
      opinion.recommendation = this.recommendation;
      return opinion;
   }

   disabledAddendumAccordionList(){
      this.$.divAddendumList.style.display = "none";
   }

   ableAddendumAccordionList(){
      this.$.divAddendumList.style.display = "block";
   }

   setOpinion(opinion){
      this.opinion = opinion;
      this.findings = opinion.finding;
      this.conclusion = opinion.conclusion;
      this.recommendation = opinion.recommendation;
      this.$.approveInfo.innerHTML = `${opinion.approver} Approved at ${opinion.approveDtime}`;
      if(opinion.addendumList){
         for(let i=0; i<opinion.addendumList.length; i++){
            // eslint-disable-next-line no-param-reassign
            opinion.addendumList[i].title = `${opinion.addendumList[i].addemdumer} Addendum at ${ opinion.addendumList[i].addendumDtime}`;
         }
         this.addendumList = opinion.addendumList;
      }
   }

   enableAddendumList(){

      this.$.approveInfo.style.display = "block";
      if(!this.$.approveInfo.classList.value.includes("active")){
         const targetElement = this.$.approveInfo;
         targetElement.classList.toggle("active");
         if(targetElement.nextElementSibling){
            targetElement.nextElementSibling.style.display = "block";
         }
      }
   }

   disableAddendumList(){

      this.$.approveInfo.style.display = "block";
      if(this.$.approveInfo.nextElementSibling) this.$.approveInfo.nextElementSibling.style.display = "block";
      this.$.approveInfo.style.display = "none";
      this.$.divAddendumList.style.display = "none";
   }

   attachContextMenu() {
      // this.$.divAddendumList.querySelectorAll("iron-autogrow-textarea").forEach(el => this.setContextMenu(el));
      this.$.divAddendumList.querySelectorAll("div.context-menus").forEach(el => this.setContextMenu(el));
   }

   setContextMenu(el) {
      el.addEventListener("contextmenu", e => this.showContextMenu(e, el));
   }

   showContextMenu(e, target) {
      e.preventDefault();

      const menus = this.$.contextMenu;

      this.elemEditor = target;

      // const rect = this.shadowRoot.querySelector(".container").getBoundingClientRect();
      // const {x, y} = rect;
      const {pageX, pageY} = e;
      const cw = this.contextWidth;
      const ch = this.contextHeight;

      const l = pageX - 35;
      const t = pageY - 25;

      let left = `${l}px`;
      if((pageX + cw) >= window.innerWidth) {
         left = `calc(100% - ${cw + 5}px)`;
      }

      let top  = `${t}px`;
      if((pageY + ch) >= window.innerHeight) {
         top = `calc(100% - ${ch + 5}px)`;
      }
      // console.log("tech page", this, "mouse.pageX", pageX, "mouse.pageY", pageY, "clientRect.x", x, "clientRect.y", y, "contextWidth", cw, "contextHeight", ch, l, t);

      menus.style.left = left;
      menus.style.top = top;

      // console.log(menus);
      // // console.log("classList.contains", menus.classList.contains("hideContextMenu"));
      if(menus.classList.contains("hideContextMenu")) {
         menus.classList.remove("hideContextMenu"); // SHOW
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: this.customUniqueKey });
      }

      this.disableContextMenuItem(target);
   }

   disableContextMenuItem(target) {
      if(!target) return;

      const menus = this.$.contextMenu;
      const list = [".do-cut", ".do-delete", ".do-paste"];
      // console.log(menus, this.elemEditor);

      if(!this.supportClipboard) {
         list.forEach(name => this.addDisableContextMenuItem(menus.querySelector(name)));
         this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
      } else {
         switch(this.elemEditor.tagName) {
         case "DIV": {
            const bol = this.isSelectText(this.elemEditor);
            if(bol) {
               this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
            } else {
               this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
            }
            break;
         }
         default:
         }

         if(Object.keys(this.opinion||{}).includes("approveDtime")) {
            this.removeDisableContextMenuItem(menus.querySelector(".do-copy-report"));
         } else {
            this.addDisableContextMenuItem(menus.querySelector(".do-copy-report"));
         }
      }
   }

   addDisableContextMenuItem(el) {
      if(!el) return;

      if(!el.classList.contains("ag-menu-option-disabled"))
         el.classList.add("ag-menu-option-disabled");
   }

   removeDisableContextMenuItem(el) {
      if(!el) return;

      if(el.classList.contains("ag-menu-option-disabled"))
         el.classList.remove("ag-menu-option-disabled");
   }

   handleCopy() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-copy").classList.contains("ag-menu-option-disabled"))) return;
      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      // console.log("onCopy", this.elemEditor);
      switch(this.elemEditor.tagName) {
      case "TEXTAREA": {
         const v = this.elemEditor.value;
         const start = this.elemEditor.selectionStart;
         const end   = this.elemEditor.selectionEnd;

         navigator.clipboard.writeText(v.substring(start, end)).then(() => {
            this.openToast(this.t("msg.opinion.clipboard.success"), false);
         });
         break;
      }
      case "DIV": {
         const text = this.selectedText(this.elemEditor);
         if(text) {
            navigator.clipboard.writeText(text).then(() => {
               this.openToast(this.t("msg.opinion.clipboard.success"), false);
            });
         }
         break;
      }
      default:
      }
   }

   handleSelectAll() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-select-all").classList.contains("ag-menu-option-disabled"))) return;

      // console.log("onSelectAll", this.elemEditor, this.elemEditor.tagName);

      switch(this.elemEditor.tagName) {
      case "TEXTAREA": {
         const ta = this.elemEditor.textarea||this.elemEditor;
         /* ta.setSelectionRange(0, this.elemEditor.value.length); */
         this.setSelectionRange(ta, 0, this.elemEditor.value.length);
         ta.select();
         ta.focus();
         break;
      }
      case "DIV": {
         this.selectText(this.elemEditor);
         break;
      }
      default:
      }
   }

   handleCopyReport() {
      if(!Object.keys(this.opinion||{}).includes("approveDtime")
            || (this.$.contextMenu.querySelector(".do-select-all").classList.contains("ag-menu-option-disabled"))) return;
      if(!this.supportClipboard) {
         this.openToast(this.t("msg.opinion.clipboard.fail"), true);
         return;
      }

      const op = this.opinion;
      const study = this.opinion.study||{};
      const lines = [];
      console.log(this.opinion, study);
      // lines.push([study.studyDescription, study.studyDtime].filter(f => f !== null).join(", "));
      // lines.push("");
      lines.push(`[${[study.patientName, study.patientID, study.patientBirthDate, study.patientSex, study.studyDtime, study.studyDescription].map(m => (m||"").trim()).join(" ")}]`);
      lines.push("");
      lines.push("[Finding]");
      lines.push(op.finding);
      lines.push("");
      lines.push("[Conclusion]");
      lines.push(op.conclusion);
      lines.push("");
      lines.push("[Recommendation]");
      lines.push(op.recommendation);
      lines.push("");

      lines.push(["Approved by", op.approver, "at", op.approveDtime].map(m => m.trim()).join(" "));

      if(op.addendumList && op.addendumList.length > 0) {
         lines.push("");
         lines.push("[Addendum]");
         op.addendumList.forEach((ad) => {
            lines.push(`[${[ad.addemdumer, "at", ad.addendumDtime].map(m => m.trim()).join(" ")}]`);
            lines.push(ad.addendum);
            lines.push("");
         });
      }

      const report = lines.join("\r\n");
      navigator.clipboard.writeText(report).then(() => {
         this.openToast(this.t("msg.opinion.clipboard.success"), false);
      });
   }

   openToast(msg, isErr) {
      const detail = {msg, isErr};
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }

   setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
         input.focus();
         input.setSelectionRange(selectionStart, selectionEnd);
      }
      else if (input.createTextRange) {
         const range = input.createTextRange();
         range.collapse(true);
         range.moveEnd("character", selectionEnd);
         range.moveStart("character", selectionStart);
         range.select();
      }
   }

   focusPosition(pos) {
      queueMicrotask(() => this.setSelectionRange(this.elemEditor.textarea||this.elemEditor, pos, pos));
      // this.setSelectionRange(input, pos, pos);
   }

   setNewValue(val) {
      const target = this.elemEditor.getAttribute("id");
      switch(target) {
      case "taFinding": {
         this.findings = val;
         break;
      }
      case "taConclusion": {
         this.conclusion = val;
         break;
      }
      case "taRecommendation": {
         this.recommendation = val;
         break;
      }
      default:
         this.elemEditor.value = val;
      }
   }

   selectText(el) {
      if (window.getSelection && document.createRange) { // Browser compatibility
         const sel = window.getSelection();
         if(sel.toString() === "") { // no text selection
            window.setTimeout(() => {
               const range = document.createRange(); // range object
               range.selectNodeContents(el); // sets Range
               sel.removeAllRanges(); // remove all ranges from selection
               sel.addRange(range);// add Range to a Selection.
            }, 0);
         }
      } else if (document.selection) { // older ie
         const sel = document.selection.createRange();
         if(sel.text === "") { // no text selection
            const range = document.body.createTextRange(); // Creates TextRange object
            range.moveToElementText(el); // sets Range
            range.select(); // make selection.
         }
      }
   }

   isSelectText() {
      if (window.getSelection && document.createRange) {
         const sel = window.getSelection();
         return (sel.toString() !== "");
      }

      if (document.selection) {
         const sel = document.selection.createRange();
         return (sel.text !== "");
      }
      return false;
   }

   selectedText() {
      if (window.getSelection && document.createRange) { // Browser compatibility
         const sel = window.getSelection();
         return sel.toString();
      }

      if (document.selection) { // older ie
         const sel = document.selection.createRange();
         return sel.text;
      }

      return null;
   }

   changeCustomContextMenuState(customContextMenuState) {
      if (this.customUniqueKey !== customContextMenuState) {
         if (!this.$.contextMenu.classList.contains("hideContextMenu"))
            this.$.contextMenu.classList.add("hideContextMenu"); // HIDE
      }
   }
}

window.customElements.define(HHTechReport.is, HHTechReport);
