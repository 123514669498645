/* eslint-disable no-undef */
/* eslint-disable no-alert,no-restricted-globals,no-param-reassign */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";
import mixinCommons from "../common-mixin";

import "@polymer/paper-dialog";
import "@polymer/paper-button";

import { is_esc, is_enter, have_to_move_to_right, have_to_move_to_left, clickEvent } from "./common-keyboard-handler";
import store from "../redux/store";
import {FilmboxActionType} from "../redux/reducers/filmbox";
import {ReportActionType} from "../redux/reducers/report";
/**
 * `logout-dialog-container`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class LogoutDialogContainer extends mixinCommons(PolymerElement) {
   static get is() {
      return "logout-dialog-container";
   }

   static get template() {
      return html`
      <style>
         /*FIXME login 창과 중복되는 CSS. CSS를 어떤 방식으로 모듈화 할 것인지 정해야 함*/
         .font {
               color: #aaaaaa;
         }

         paper-button[focused]  {
               background: #0087cb;
         }

         paper-button {
               min-width: 120px;
               height: 35px;
               border: 0;
               font-size: 12pt;
               color: #ffffff;
               cursor: pointer;
               border-radius: 2px;
               background: #4c5667;
         }

         #logoutDialog {
               background: #2d333f;
               width: 470px;
               height: 325px;
               border-top: 5px #0087cb solid;
         }

         .classA {
               text-align: center;
               padding: 25px;
         }

         .logout-image {
               border-radius: 50%;
               border: 5px #0087cb solid;
               width: 150px;
               height: 150px;
         }
      </style>

      <!-- <iron-ajax id="ajax"></iron-ajax> -->

      <!--로그아웃 다이얼로그-->
      <paper-dialog id="logoutDialog" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed" modal>
         <div>
               <div>
                  <!--<div class="logout-image">-->
                     <!--로그아웃 이미지-->
                  <!--</div>-->
               </div>
               <div class="classA">
                  <p style="color: #ffffff; font-size: 18pt">{{username}}</p>
                  <p style="color: #aaaaaa;font-size: 12pt ">{{t("msg.confirmLogout.1")}}</p>
               </div>
               <div class="classA">
                  <paper-button id="logoutBtn" on-click="doLogout">{{t("button.logout")}}</paper-button>
                  &nbsp;
                  <paper-button id="cancelBtn" on-click="doCancel">{{t("button.cancel")}}</paper-button>
               </div>
         </div>
      </paper-dialog>
      `;
   }

   static get properties() {
      return {
         g_popup: {
            type: Object
         }
      };
   }

   ready() {
      super.ready();
      self.focus();

      ["click", "keydown", "keypress", "keyup"].forEach(eventType => this.$.logoutDialog.addEventListener(eventType, e => e.stopPropagation()));

      // this.$.logoutBtn.addEventListener("click", () => {
      //    this.doLogout();
      // });
      // this.$.cancelBtn.addEventListener("click", () => {
      //    this.$.logoutDialog.close();
      //    window.canceled();
      // });

      if (localStorage.getItem("user")) {
         this.username = JSON.parse(localStorage.getItem("user")).name;
      }

      /* Sync with FLeader: START  ------------------------------------------------------------------ -*/
      const buttons = this.$.logoutDialog.getElementsByTagName("paper-button");
      this.toggle = () => [...buttons].forEach((button, index) => {
         const set = (this.focused_index === index);
         button.focused = set;
         button.active = set;
         if(set) button.focus();
      });

      // TAB/Shift-Tab/<--/-->/ESC/Enter 처리
      this.$.logoutDialog.addEventListener("keyup", (e) => {
         if(have_to_move_to_right(e) || have_to_move_to_left(e)) {
            this.focused_index = 1 - this.focused_index;
            this.toggle();
         } else if(is_enter(e)) {
            [...buttons][this.focused_index].dispatchEvent(clickEvent);
         } else if(is_esc(e)) {
            [...buttons].filter( button => button.id === "cancelBtn" ).map(cancelBtn => cancelBtn.dispatchEvent(clickEvent));
         }
         e.stopPropagation();
      });
      /* Sync with FLeader: EDN  ------------------------------------------------------------------ -*/

      window.addEventListener("LOGOUT_OK", () => this.doLogout());

      // link logout dialog 다국어처리 제외
      this.setLinkLogoutInfo();

      window.addEventListener("message", (e) => {
         switch ((e.data || {}).event) {
         case "POPUP_LOGOUT_OK":
            this.doLogout();
            break;
         default:
            break;
         }
      });
   }

   close_if_esc(e) {
      if(is_esc(e)) {
         this.$.cancelBtn.dispatchEvent(clickEvent);
      }
      e.stopPropagation();
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      // Link login 시 FLeader를 사용하지 않기 때문에 구분해서 처리함.
      if(window.dialog_opened) window.dialog_opened({dialog:this.$.logoutDialog, is_modal:true});
   }

   dialog_closed() {
      // Link login 시 FLeader를 사용하지 않기 때문에 구분해서 처리함.
      if(window.dialog_closed) window.dialog_closed();
   }

   callLogoutDialog() {
      this.$.logoutDialog.open();
      this.focused_index = 0;
      this.toggle(); // 기본값으로 첫번째 버튼에 focus를 줌
   }

   setPopup(popup) {
      this.g_popup = popup;
   }

   doLogout() {
      const { id } = JSON.parse(localStorage.getItem("user"));
      window.postMessage({event: "READING_OFF", id }, document.location.href);
      this.clearSession();

      window.postMessage({close: true}, document.location.href);
      window.postMessage({popupClose: true}, document.location.href);
      store.dispatch({ type: FilmboxActionType.CLOSE_FILMBOX });
   }

   clearSession() {
      // localStorage 제거
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwtExpTime");
      localStorage.removeItem("link");
      localStorage.removeItem("patientId");

      // sessionStorage.removeItem(__CERT_USER_DN__);
      store.dispatch({ type: ReportActionType.REMOVE_USERDN });

      localStorage.removeItem(__CERT_STORAGE__);

      // login 페이지로 이동
      window.location.href = "/login";
   }

   doCancel() {
      this.$.logoutDialog.close();

      // Link login 시 FLeader를 사용하지 않기 때문에 구분해서 처리함.
      if(window.canceled) window.canceled();
   }

   setLinkLogoutInfo() {
      const user = JSON.parse(localStorage.getItem("user"));
      const {name} = user;

      if(name === "link login") {
         this.$.logoutDialog.querySelectorAll("p").forEach((el) => {
            if(!el.innerText) el.innerText = "Are you sure you want to Logout?";
         });
         this.$.logoutDialog.querySelector("#logoutBtn").innerText = "Logout";
         this.$.logoutDialog.querySelector("#cancelBtn").innerText = "Cancel";
      }
   }
}

window.customElements.define(LogoutDialogContainer.is, LogoutDialogContainer);
